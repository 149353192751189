import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TableauEmbed from '../../../../../../../../../components/tableau/TableauEmbed';
import kpiNameToTableauViewMapper from '../constants/KpiNameToTableauViewNameMapper';

const ChartBox: React.FC = () => {
  const { submoduleName } = useParams<{
    submoduleName: string;
    moduleName: string;
    workspaceId: string;
  }>();
  const [lastSubmoduleName, setLastSubmoduleName] = useState('');
  const [viewUrl, setViewUrl] = useState<string>('');

  useEffect(() => {
    if (lastSubmoduleName !== submoduleName) {
      const tableauHost = process.env.REACT_APP_TABLEAU_SERVER_HOST;
      const site = process.env.REACT_APP_TABLEAU_SERVER_SITE;
      const workbook = process.env.REACT_APP_TABLEAU_SERVER_WORKBOOK;
      const viewName: string | null = kpiNameToTableauViewMapper(submoduleName!);
      let url: string | null = `${tableauHost}`;
      setLastSubmoduleName(submoduleName!);

      url =
        viewName !== null ? `${url}/t/${site}/views/${workbook}/${viewName}?:embed=y&:showVizHome=no&:tabs=no` : null;
      setViewUrl(url!);
    }
  }, [submoduleName, lastSubmoduleName]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        position: 'relative',
        height: '100vh',
      }}
    >
      <TableauEmbed url={viewUrl}></TableauEmbed>
    </Box>
  );
};

export default ChartBox;
