import React from 'react';
import { Box, InputBase, IconButton, useTheme } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

interface CommentBoxProps {
  newComment: string;
  setNewComment: (value: string) => void;
  isLoading: boolean;
  handleNewCommentRequest: () => Promise<void>;
}

const NewCommentBox: React.FC<CommentBoxProps> = ({
  newComment,
  setNewComment,
  isLoading,
  handleNewCommentRequest,
}) => {
  const { palette } = useTheme();
  return (
    <Box
      display="flex"
      border={`1px solid ${palette.gray[400]}`}
      pl="7px"
      borderRadius="4px"
      justifyContent="space-between"
      mt={'10px'}
      width={'100%'}
    >
      <InputBase
        value={newComment}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNewComment(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') void handleNewCommentRequest();
        }}
        placeholder="Comment or Reply"
        inputProps={{
          sx: {
            fontSize: '12px',
            '&::placeholder': {
              color: palette.gray[400],
            },
          },
        }}
      />
      <IconButton
        disabled={isLoading}
        onClick={() => {
          void handleNewCommentRequest();
        }}
      >
        <ArrowCircleRightOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default NewCommentBox;
