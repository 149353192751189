import { Settings, Upload, Error, Comment } from '@mui/icons-material';
import { Box, Card, CardContent, Divider, IconButton, Typography, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';

import FileUploadIconButton from '../../../../../components/materials/actions/FileUploadIconButton';
import RedesignAppButton from '../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../context/toastContext';
import { formatDate } from '../../../../../utils/dateUtils';
import { useAppSelector } from '../../../../../utils/hooks/useAppSelector';
import { useUploadExcelTemplateMutation } from '../../../../../utils/redux/api';
import { StyledChipContainer } from '../Workspace/Data/components/DataTableHeader';

interface BankCardProps {
  title: string;
  location: string;
  issues?: number;
  comments?: number;
  progress?: number[];
  onSettingsClick?: () => void;
  onButtonClick?: () => void;
  dueDate: string;
  workspaceId: number;
}

const BankCard: React.FC<BankCardProps> = ({
  title,
  location,
  onSettingsClick,
  onButtonClick,
  dueDate,
  workspaceId,
  issues,
  comments,
  progress,
}) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);

  const [uploadExcelTemplateMutation] = useUploadExcelTemplateMutation();
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const userRoles = useAppSelector((state) => state.auth.userDetails.roles ?? []);
  const shouldShowUploadButton = userRoles.includes('data_lead') || userRoles.includes('admin');

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file == null) {
      handleOpenToast({
        severity: 'error',
        message: 'No file selected',
      });
      return;
    }

    try {
      if (workspaceId !== undefined) {
        setUploadLoading(true);
        await uploadExcelTemplateMutation({
          workspaceId: workspaceId.toString(),
          excelFile: file,
        }).unwrap();

        setUploadLoading(false);
        handleOpenToast({
          severity: 'success',
          message: 'File uploaded successfully, please wait 5 minutes for KPI calculation',
        });
      }
    } catch (error) {
      setUploadLoading(false);
      handleOpenToast({
        severity: 'error',
        message: 'File upload error',
      });
      console.error('File upload error:', error);
    }
  };

  return (
    <Card
      sx={{
        border: `1px solid ${palette.gray[250]}`,
        borderRadius: '8px',
      }}
      elevation={2}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          gap: 2,
          padding: '16px !important',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} width="100%">
          <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth="75%">
            <Typography
              variant="headlineSmall"
              title={title}
              sx={{
                flexShrink: 1,
                width: '100%',
                color: palette.gray.black,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {title}
            </Typography>
            <Typography variant="bodySmall">{location}</Typography>
          </Box>
          <Box display="flex" flexDirection="row" sx={{ alignItems: 'center' }}>
            <IconButton aria-label="settings" onClick={onSettingsClick} sx={{ p: '8px' }}>
              <Settings sx={{ fontSize: '24px', color: palette.green[500] }} />
            </IconButton>
            {shouldShowUploadButton && !uploadLoading ? (
              <FileUploadIconButton
                onFileChange={handleFileChange}
                acceptedFileTypes={
                  'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }
              >
                <Upload sx={{ fontSize: '24px', color: palette.green[500] }} />
              </FileUploadIconButton>
            ) : uploadLoading ? (
              <AppCircularProgress size="20px" sx={{ p: '8px' }} />
            ) : null}
          </Box>
        </Box>
        <Divider sx={{ color: palette.gray[300] }} />
        <Box px={2} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
            <Typography variant="titleSmallBold" sx={{ color: palette.gray[500] }}>
              Due Date
            </Typography>
            <Typography variant="bodyMedium" sx={{ color: palette.gray[500] }}>
              {formatDate(dueDate)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
            <Typography variant="titleSmallBold" sx={{ color: palette.gray[500] }}>
              Progress
            </Typography>
            <Typography variant="bodyMedium" sx={{ color: palette.gray[500] }}>
              {progress !== undefined && progress?.length > 0 ? `${progress?.[0]} / ${progress?.[1]} modules` : '---'}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <StyledChipContainer backgroundColor={palette.red[200]} color={palette.red[700]}>
              <Error fontSize="small" sx={{ color: ({ palette }) => palette.red[500] }} />
              {(issues ?? '0') + ' '}
            </StyledChipContainer>
            <StyledChipContainer backgroundColor={palette.yellow[300]} color={palette.yellow[700]}>
              <Comment fontSize="small" sx={{ color: ({ palette }) => palette.yellow[500] }} />
              {(comments ?? '0') + ' '}
            </StyledChipContainer>
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <RedesignAppButton onClick={onButtonClick} appVariant={'filled'} widePadding={true}>
            <Typography variant="buttonMedium">Enter Portal</Typography>
          </RedesignAppButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BankCard;
