import { type DocumentUpload, type WorkspaceDocument } from '../../types/WorkspaceDocument';
import { rebexApi } from './baseRebexEndpoints';

export const workspaceDocumentsExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspaceDocuments: builder.query<WorkspaceDocument[], { workspaceId: number }>({
      query: ({ workspaceId }) => `/workspaces/${workspaceId}/documents`,
    }),
    getWorkspaceDocumentsSASToken: builder.query<{ sas_token: string }, { workspaceId: number }>({
      query: ({ workspaceId }) => `/workspaces/documents/blob-token?workspace_id=${workspaceId}`,
    }),
    uploadWorkspaceDocument: builder.mutation<string, DocumentUpload>({
      query: (upload) => {
        const formData = new FormData();
        formData.append('file', upload.file);
        formData.append('workspace_id', upload.workspace_id.toString());
        formData.append('document_description', upload.document_description);
        formData.append('document_name', upload.document_name);

        return {
          url: `/workspaces/upload-document`,
          method: 'POST',
          body: formData,
          contentType: 'multipart/form-data',
        };
      },
    }),
    updateWorkspaceDocument: builder.mutation<WorkspaceDocument, Partial<WorkspaceDocument>>({
      query: (document: WorkspaceDocument) => ({
        url: `/workspaces/documents/${document.document_id}`,
        method: 'PUT',
        body: { new_name: document.document_name },
      }),
    }),
    deleteWorkspaceDocument: builder.mutation<WorkspaceDocument, number>({
      query: (id: number) => ({
        url: `/workspaces/documents/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});
