import { Box, ButtonBase, type Palette, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../../utils/hooks/useAppSelector';
import { useGetUserSubmodulesQuery } from '../../../../../../../utils/redux/api';
import { selectUserRoles } from '../../../../../../../utils/redux/authSlice';
import { formatNameForPath } from '../../../../../../../utils/stringUtils';
import { type Submodule } from '../../../../../../../utils/types/SubModule';
import { SubmodulesEnum } from '../../../../../../../utils/types/SubmodulesEnum';

const mapSubmodulesToPaths = (submodules: Submodule[]): SubmodulePathItem[] =>
  submodules.map((submodule) => ({
    ...submodule,
    path: `${formatNameForPath(submodule.name)}/${submodule.submodule_id}`,
  }));

const submodulesLabelBySubmodule = {
  [SubmodulesEnum.ProductAndSegment]: 'Revenues',
  [SubmodulesEnum.TypeAndFunction]: 'Costs',
  [SubmodulesEnum.Customers]: 'Customers',
  [SubmodulesEnum.ProductSales]: 'Products',
  [SubmodulesEnum.OnlineAndMobileCustomers]: 'Digital Channels',
  [SubmodulesEnum.BranchAndATMCustomers]: 'Physical Channels',
  [SubmodulesEnum.ContactCenter]: 'Call Center Channels',
  [SubmodulesEnum.PersonalBankingFTE]: 'Employee (HR)',
};

interface SubmodulePathItem extends Submodule {
  path: string;
}

interface Props {
  submodules: Submodule[];
  refetchSubmodules?: () => Promise<void>;
}

const getTabStyles = (isDisabled: boolean, isSelected: boolean, palette: Palette) => {
  if (isDisabled)
    return {
      color: palette.gray[400],
      bgColor: palette.gray[200],
    };

  if (isSelected)
    return {
      color: palette.green[500],
      bgColor: palette.green[50],
      borderBottom: `2px solid ${palette.green[500]}`,
    };

  return {
    color: palette.gray[500],
    '&:hover': {
      bgcolor: palette.green[100],
      color: palette.green[400],
      borderBottom: `2px solid ${palette.green[400]}`,
    },
  };
};

const SubmodulesTabs: React.FC<Props> = ({ submodules: submodulesData, refetchSubmodules }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { workspaceId } = useParams();

  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const { data: userSubmodules } = useGetUserSubmodulesQuery(Number(workspaceId));
  const [submodules, setSubmodules] = useState<SubmodulePathItem[]>([]);

  useEffect(() => {
    setSubmodules(mapSubmodulesToPaths(submodulesData));
  }, [submodulesData]);

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      {Object.keys(submodulesLabelBySubmodule).map((submoduleItem) => {
        const item = submodules.find((submodule) => submodule.name === submoduleItem);
        if (item === undefined) return null;

        const isSelected = pathname.includes(`/${item.path}`);
        const disabled =
          userRoles.includes('data_collector') &&
          !(userSubmodules?.some((userSubmodule) => userSubmodule.submodule_id === item.submodule_id) ?? false);

        return (
          <ButtonBase
            onClick={() => {
              if (refetchSubmodules !== undefined) void refetchSubmodules();
              navigate(item.path);
            }}
            disabled={disabled}
            key={item.submodule_id}
            sx={{
              px: 1,
              py: 2,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              ...getTabStyles(disabled, isSelected, palette),
            }}
          >
            <Typography variant="labelLarge">{submodulesLabelBySubmodule[item.name]}</Typography>
            {!disabled && <Typography variant="bodySmall">{item.status}</Typography>}
          </ButtonBase>
        );
      })}
    </Box>
  );
};

export default SubmodulesTabs;
