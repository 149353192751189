import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useWorkspaceDataContext } from '../../../../../context/WorkspaceDataContext';
import { formatNameForPath } from '../../../../../utils/stringUtils';
import { type Workspace } from '../../../../../utils/types/Workspace';
import BankCard from './BankCard';

interface DynamicTabContentProps {
  data?: Workspace[];
}

const DynamicTabContent: React.FC<DynamicTabContentProps> = ({ data }) => {
  const navigate = useNavigate();
  const { setActiveWorkspace } = useWorkspaceDataContext();

  const mountWorkspaceUrl = (workspaceName: string, workspaceId: number | null) => {
    const workspacePath = formatNameForPath(workspaceName);
    return `/app/workspaces/${workspacePath}/${workspaceId}`;
  };

  const handleSettingsClick = (workspace: Workspace) => {
    setActiveWorkspace(workspace);
    const url = mountWorkspaceUrl(workspace.workspace_name, workspace.workspace_id);
    navigate(`${url}/edit-workspace`);
  };

  const handleWorkspaceClick = (workspace: Workspace) => {
    setActiveWorkspace(workspace);
    const url = mountWorkspaceUrl(workspace.workspace_name, workspace.workspace_id);
    navigate(`${url}`);
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      {data?.map((workspace, index) => (
        <Grid item key={workspace.workspace_id ?? `workspace-${index}`} xs={12} sm={6} lg={4} xl={3}>
          <BankCard
            title={workspace?.workspace_name ?? ''}
            location={workspace.country}
            onSettingsClick={() => {
              handleSettingsClick(workspace);
            }}
            onButtonClick={() => {
              handleWorkspaceClick(workspace);
            }}
            dueDate={workspace?.final_due_date}
            workspaceId={Number(workspace.workspace_id)}
            issues={workspace.issues}
            comments={workspace.comments}
            progress={workspace.progress}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DynamicTabContent;
