import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { OnlineAndMobileCustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { handleOpenComments } from '../../utils/handleOpenComments';

const DigitalSalesFunnel: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, OnlineAndMobileCustomersTables.DigitalSalesFunnel),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse, isLoading: isLoadingDataTable } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: OnlineAndMobileCustomersTables.DigitalSalesFunnel,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Checking accounts digital sales funnel', editable: true, indentLevel: 1 },
    { label: 'Number of page visits', editable: true, indentLevel: 2 },
    { label: 'Applications started', editable: true, indentLevel: 2 },
    { label: 'Applications completed', editable: true, indentLevel: 2 },
    { label: 'Applications which passed fraud and risk checks', editable: true, indentLevel: 2 },
    { label: 'Applications approved', editable: true, indentLevel: 2 },
    { label: 'Accounts activated by client', editable: true, indentLevel: 2 },
    { label: 'Accounts funded by client in the first 30 days', editable: true, indentLevel: 2 },
    {
      label: 'Accounts closed by bank within the first 3 months due to fraud / funding issues',
      editable: true,
      indentLevel: 2,
    },
    { label: 'Accounts closed by clients within the first 3 months', editable: true, indentLevel: 2 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Online/Web public site (#)', editable: true },
    { key: 'B', label: 'Online/web secured site (#)', editable: true },
    { key: 'C', label: 'Mobile app (#)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: OnlineAndMobileCustomersTables.DigitalSalesFunnel,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B', 'C'],
          rowCount: 10,
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: OnlineAndMobileCustomersTables.DigitalSalesFunnel,
          values: {
            ...dataTable?.values,
            [cellId]: newValue,
          },
        },
        columns: ['A', 'B', 'C'],
        rowCount: 10,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default DigitalSalesFunnel;
