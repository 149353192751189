import { SubmodulesEnum } from './SubmodulesEnum';

export enum ProductAndSegmentTables {
  AverageBalanceByProduct = 'average_balance_by_product',
  RevenuesByProduct = 'revenues_by_product',
  RevenuesBySegment = 'revenues_by_segment',
}

export enum TypeAndFunctionTables {
  ChannelCostsAndSupportFunctionsCosts = 'channel_costs_and_support_functions_costs',
  CostByType = 'cost_by_type',
  MarketingCostsTable1TotalMarketing = 'marketing_costs_table_1_total_marketing',
  MarketingCostsTable2DigitalChannelsMarketingSpend = 'marketing_costs_table_2_digital_channels_marketing_spend',
  MarketingCostsTable3DigitalChannels = 'marketing_costs_table_3_digital_channels',
  MarketingCostsTable3SalesAndPromotion = 'marketing_costs_table_3_sales_and_promotion',
}

export enum PersonalBankingFTETables {
  BranchFTE = 'branch_fte',
  BranchFTECompensation = 'branch_fte_compensation',
  FrontAndMiddleOfficesAndSupportFTEFrontOffice = 'front_and_middle_offices_and_support_fte_front_office',
  FrontAndMiddleOfficesFTECompensationFrontOffice = 'front_and_middle_offices_fte_compensation_front_office',
  FrontAndMiddleOfficesFTECompensationMiddleOffice = 'front_and_middle_offices_fte_compensation_middle_office',
  OperationsFTE = 'operations_fte',
  OperationsFTECompensation = 'operations_fte_compensation',
}

export enum CustomersTables {
  CustomerProductHolding = 'customer_product_holding',
  DistributionByAge = 'distribution_by_age',
  DistributionByProductHoldingTable1 = 'distribution_by_product_holding_table_1',
  DistributionByProductHoldingTable2 = 'distribution_by_product_holding_table_2',
  DistributionOfCheckingCustomerAverageBalance = 'distribution_of_checking_customer_average_balance',
  DistributionOfTenure = 'distribution_of_tenure',
  NewAndLostCustomers = 'new_and_lost_customers',
  TotalCustomersOverviewAndSegmentsTable1 = 'total_customers_overview_and_segments_table_1',
  TotalCustomersOverviewAndSegmentsTable2 = 'total_customers_overview_and_segments_table_2',
  TotalCustomersOverviewAndSegmentsTable3 = 'total_customers_overview_and_segments_table_3',
}

export enum ProductSalesTables {
  AccountsClosed = 'accounts_closed',
  CallCenterRemoteChannels = 'call_center_remote_channels',
  DigitalChannels = 'digital_channels',
  NewProductsSoldToNewToBankCustomers = 'new_products_sold_to_new_to_bank_customers',
  PhysicalChannels = 'physical_channels',
  SummaryOfSalesByProductType = 'summary_of_sales_by_product_type',
}

export enum OnlineAndMobileCustomersTables {
  ChatbotUsageTable1 = 'chatbot_usage_table_1',
  ChatbotUsageTable2 = 'chatbot_usage_table_2',
  CustomerActivity90DayByAge = 'customer_activity_90_day_by_age',
  CustomerActivityTable1 = 'customer_activity_table_1',
  CustomerActivityTable2 = 'customer_activity_table_2',
  CustomerActivityTable3 = 'customer_activity_table_3',
  DigitalEnrollmentTable1 = 'digital_enrollment_table_1',
  DigitalEnrollmentTable2 = 'digital_enrollment_table_2',
  DigitalSalesFunnel = 'digital_sales_funnel',
  QualityOfSalesInDigital = 'quality_of_sales_in_digital',
  TransactionsInDigital = 'transactions_in_digital',
  UsageOfDigitalFeatures = 'usage_of_digital_features',
}

export enum BranchAndATMCustomersTables {
  CustomerActivityInPhysicalChannelsTable1 = 'customer_activity_in_physical_channels_table_1',
  CustomerActivityInPhysicalChannelsTable2 = 'customer_activity_in_physical_channels_table_2',
  NumberOfBranchesAndATMs = 'number_of_branches_and_atms',
  QualityOfSalesInBranch = 'quality_of_sales_in_branch',
  TransactionsInDigital = 'transactions_in_digital',
}

export enum ContactCenterTables {
  CallCenterMetricsTable1 = 'call_center_metrics_table_1',
  CallCenterMetricsTable2 = 'call_center_metrics_table_2',
  CustomerActivityInCallCenter = 'customer_activity_in_call_center',
  TransactionsInCallCenter = 'transactions_in_call_center',
}

type UnionOfEnumValues<T> = T[keyof T];

export type DataTableValues =
  | UnionOfEnumValues<typeof ProductAndSegmentTables>
  | UnionOfEnumValues<typeof TypeAndFunctionTables>
  | UnionOfEnumValues<typeof PersonalBankingFTETables>
  | UnionOfEnumValues<typeof CustomersTables>
  | UnionOfEnumValues<typeof ProductSalesTables>
  | UnionOfEnumValues<typeof OnlineAndMobileCustomersTables>
  | UnionOfEnumValues<typeof BranchAndATMCustomersTables>
  | UnionOfEnumValues<typeof ContactCenterTables>;

export const DataTablesBySubmodule = {
  [SubmodulesEnum.ProductAndSegment]: ProductAndSegmentTables,
  [SubmodulesEnum.TypeAndFunction]: TypeAndFunctionTables,
  [SubmodulesEnum.PersonalBankingFTE]: PersonalBankingFTETables,
  [SubmodulesEnum.Customers]: CustomersTables,
  [SubmodulesEnum.ProductSales]: ProductSalesTables,
  [SubmodulesEnum.OnlineAndMobileCustomers]: OnlineAndMobileCustomersTables,
  [SubmodulesEnum.BranchAndATMCustomers]: BranchAndATMCustomersTables,
  [SubmodulesEnum.ContactCenter]: ContactCenterTables,
};

export type TabKeys =
  | Exclude<DataTableValues, BranchAndATMCustomersTables.TransactionsInDigital>
  | `${BranchAndATMCustomersTables.TransactionsInDigital}_physical`;

export const TabKeysBySubmodule: Record<SubmodulesEnum, TabKeys[]> = {
  [SubmodulesEnum.ProductAndSegment]: [
    ProductAndSegmentTables.AverageBalanceByProduct,
    ProductAndSegmentTables.RevenuesByProduct,
    ProductAndSegmentTables.RevenuesBySegment,
  ],
  [SubmodulesEnum.TypeAndFunction]: [
    TypeAndFunctionTables.ChannelCostsAndSupportFunctionsCosts,
    TypeAndFunctionTables.CostByType,
    TypeAndFunctionTables.MarketingCostsTable1TotalMarketing,
  ],
  [SubmodulesEnum.PersonalBankingFTE]: [
    PersonalBankingFTETables.BranchFTE,
    PersonalBankingFTETables.BranchFTECompensation,
    PersonalBankingFTETables.FrontAndMiddleOfficesAndSupportFTEFrontOffice,
    PersonalBankingFTETables.FrontAndMiddleOfficesFTECompensationFrontOffice,
    PersonalBankingFTETables.OperationsFTE,
    PersonalBankingFTETables.OperationsFTECompensation,
  ],
  [SubmodulesEnum.Customers]: [
    CustomersTables.CustomerProductHolding,
    CustomersTables.DistributionByAge,
    CustomersTables.DistributionByProductHoldingTable1,
    CustomersTables.DistributionOfCheckingCustomerAverageBalance,
    CustomersTables.DistributionOfTenure,
    CustomersTables.NewAndLostCustomers,
    CustomersTables.TotalCustomersOverviewAndSegmentsTable1,
  ],
  [SubmodulesEnum.ProductSales]: [
    ProductSalesTables.AccountsClosed,
    ProductSalesTables.CallCenterRemoteChannels,
    ProductSalesTables.DigitalChannels,
    ProductSalesTables.NewProductsSoldToNewToBankCustomers,
    ProductSalesTables.PhysicalChannels,
    ProductSalesTables.SummaryOfSalesByProductType,
  ],
  [SubmodulesEnum.OnlineAndMobileCustomers]: [
    OnlineAndMobileCustomersTables.ChatbotUsageTable1,
    OnlineAndMobileCustomersTables.CustomerActivity90DayByAge,
    OnlineAndMobileCustomersTables.CustomerActivityTable1,
    OnlineAndMobileCustomersTables.DigitalEnrollmentTable1,
    OnlineAndMobileCustomersTables.DigitalSalesFunnel,
    OnlineAndMobileCustomersTables.QualityOfSalesInDigital,
    OnlineAndMobileCustomersTables.TransactionsInDigital,
    OnlineAndMobileCustomersTables.UsageOfDigitalFeatures,
  ],
  [SubmodulesEnum.BranchAndATMCustomers]: [
    BranchAndATMCustomersTables.CustomerActivityInPhysicalChannelsTable1,
    BranchAndATMCustomersTables.NumberOfBranchesAndATMs,
    BranchAndATMCustomersTables.QualityOfSalesInBranch,
    `${BranchAndATMCustomersTables.TransactionsInDigital}_physical`,
  ],
  [SubmodulesEnum.ContactCenter]: [
    ContactCenterTables.CallCenterMetricsTable1,
    ContactCenterTables.CustomerActivityInCallCenter,
    ContactCenterTables.TransactionsInCallCenter,
  ],
};
