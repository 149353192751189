import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import ChartBox from './components/ChartBox';
import TableauAuthenticator from '../../../../../../../DataManagementHub/components/TableauAuthenticator';
import { useParams } from 'react-router-dom';
import getTicketRedeemTimestamp from '../../../../../../../DataManagementHub/helpers/getTicketRedeemTimestamp';
import { TableauAuthContext } from '../../../../../../../DataManagementHub/contexts/tableauAuthProvider';

const isTableauAuthenticated = (workspaceId: string | undefined): boolean => {
  const ticketRedeemTs = getTicketRedeemTimestamp(Number(workspaceId));
  return ticketRedeemTs !== null && ticketRedeemTs !== undefined;
};

const SubModules: React.FC = () => {
  const { workspaceId } = useParams<{ workspaceId: string; moduleName: string }>();
  const [tableauAuthenticated, setTableauAuthenticated] = useState(isTableauAuthenticated(workspaceId));
  const tableauAuthContext = useContext(TableauAuthContext);
  if (tableauAuthContext === undefined) {
    throw new Error('TableauAuthContext is undefined');
  }
  const { isSuccess: isTicketSuccess, trustedTicket } = tableauAuthContext ?? {};
  const onLoadTableau = () => {
    setTimeout(() => {
      setTableauAuthenticated(true);
    }, 2000); // this is to ensure that the auth token will be stored. at most it takes about 900 ms to be stored, but this is to be safe
  };

  return (
    <>
      {!tableauAuthenticated && (
        <>
          Loading Tableau authentication...
          <TableauAuthenticator
            onLoadTableau={onLoadTableau}
            isTicketSuccess={isTicketSuccess}
            trustedTicket={trustedTicket}
          />
        </>
      )}
      {tableauAuthenticated && (
        <Box sx={{ flexGrow: 1 }}>
          <ChartBox />
        </Box>
      )}
    </>
  );
};

export default SubModules;
