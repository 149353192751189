import { Box, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { ToastContext } from '../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../utils/hooks/useAppSelector';
import { useGetIsFullReportFileUploadedQuery } from '../../../../../../../utils/redux/api';
import DownloadFullReportButton from './components/DownloadFullReportButton';
import KPICategories from './components/KPICategories';
import SubmodulesPlaceholder from './components/SubmodulesPlaceholder';
import UploadFullReportButton from './components/UploadFullReportButton';
import { selectUserRoles } from '../../../../../../../utils/redux/authSlice';

const Modules: React.FC = () => {
  const { palette } = useTheme();
  const { workspaceId, submoduleName } = useParams<{ workspaceId: string; submoduleName: string }>();
  const { activeWorkspace } = useWorkspaceDataContext();
  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const { handleOpenToast } = useContext(ToastContext);

  const workspaceName = activeWorkspace?.workspace_name;

  const shouldRenderDownloadButton = activeWorkspace?.workspace_name !== undefined && workspaceId !== undefined;

  const {
    refetch: refetchIsFileUploaded,
    data: isFileUploaded,
    isError: isCheckError,
    isLoading: isCheckLoading,
  } = useGetIsFullReportFileUploadedQuery(Number(workspaceId));

  const handleUploadFinished = async () => {
    await refetchIsFileUploaded();
  };

  useEffect(() => {
    if (isCheckError) {
      handleOpenToast({
        severity: 'error',
        message: 'Error checking file upload status',
      });
    }
  }, [handleOpenToast, isCheckError]);

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        sx={{
          border: '1px solid',
          borderRadius: '0 0 8px 8px',
          backgroundColor: palette.gray.white,
          borderColor: palette.gray[300],
          padding: '16px 24px',
          mb: '16px',
        }}
        border={1}
      >
        <KPICategories />
        <Box display="flex" alignItems="center" gap={1}>
          {shouldRenderDownloadButton && (
            <DownloadFullReportButton
              workspaceId={Number(workspaceId)}
              workspaceName={workspaceName!}
              loading={isCheckLoading}
              disabled={isFileUploaded === false}
            />
          )}
          {userRoles.includes('admin') && <UploadFullReportButton onUploadFinished={handleUploadFinished} />}
        </Box>
      </Box>
      {/* <SubModules /> */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          backgroundColor: palette.gray.white,
          padding: '16px 24px',
          border: '1px',
          borderRadius: '8px',
        }}
      >
        {submoduleName === undefined && <SubmodulesPlaceholder />}
        <Outlet />
      </Box>
    </>
  );
};

export default Modules;
