import { CheckBox, CheckBoxOutlineBlank, Error } from '@mui/icons-material';
import { Autocomplete, Backdrop, Checkbox, Grid, TextField, useTheme } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../context/toastContext';
import countries, { type Country } from '../../../../../utils/countries';
import {
  useAddPeerGroupMutation,
  useDeletePeerGroupMutation,
  useGetPeerGroupQuery,
  useUpdatePeerGroupMutation,
} from '../../../../../utils/redux/api';
import { type PeerGroup } from '../../../../../utils/types/PeerGroup';
import { type Workspace } from '../../../../../utils/types/Workspace';
import ModalWrapper from './ModalWrapper';

interface Props {
  mode: 'create' | 'edit' | 'duplicate' | 'delete';
  record?: PeerGroup | null;
  open: boolean;
  clients?: Workspace[];
  handleClose: () => void;
}
const literals = {
  create: {
    title: 'New Peer Group',
    summary: 'This action will create a new peer group for outlier analysis.',
    submit: 'Create',
  },
  edit: {
    title: 'Edit Peer Group',
    summary: 'This action will edit the selected existing peer group.',
    submit: 'Save Changes',
  },
  duplicate: {
    title: 'Duplicate Peer Group',
    summary: 'This action will duplicate the selected existing peer group.',
    submit: 'Duplicate',
  },
  delete: {
    title: 'Do you want to delete this Peer Group?',
    summary: 'This action will delete this Peer Group permanently.',
    submit: 'Delete',
  },
};

const RecordModal: React.FC<Props> = ({ mode, record, open, clients, handleClose }) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * 
  const { data, error, isLoading } = useGetItemsQuery(undefined, {
    skip: !shouldFetch,  // Skip the query if shouldFetch is false
  });
   */

  const {
    data: apiRecord,
    isFetching: apiLoading,
    refetch: apiRefetch,
  } = useGetPeerGroupQuery(Number(record?.id), {
    skip: !open || mode === 'create' || mode === 'delete' || record === null || record === undefined,
  });
  useEffect(() => {
    if (record !== null && record !== undefined && ['edit', 'duplicate'].includes(mode) && open) {
      void apiRefetch();
    }
  }, [record, mode, open, apiRefetch]);
  useEffect(() => {
    if (apiRecord !== null && apiRecord !== undefined) {
      setGroupName(apiRecord.name);
      setPrimaryClient(clients!.find((client) => client.workspace_id === apiRecord.workspace_id) ?? null);
      const recordCountries = apiRecord.countries.split(',');
      setGroupCountries(countries.filter((country) => recordCountries.includes(country.label)));
      const recordPeers =
        apiRecord.members !== null && apiRecord.members !== undefined
          ? apiRecord.members.map((member) => member.workspace_id).filter((id) => id !== apiRecord.workspace_id)
          : [];
      setGroupPeers(clients!.filter((client) => recordPeers.includes(Number(client.workspace_id))));
      setBaseYear(apiRecord.base_year);
    }
  }, [apiRecord, clients]);

  const [createPeerGroup] = useAddPeerGroupMutation();
  const [updatePeerGroup] = useUpdatePeerGroupMutation();
  const [deletePeerGroup] = useDeletePeerGroupMutation();

  const [peers, setPeers] = useState<Workspace[]>([]);
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i < 20; i++) {
    years.push(currentYear - i);
  }

  const [groupName, setGroupName] = useState<string>('');
  const [primaryClient, setPrimaryClient] = useState<Workspace | null>(null);
  const selectPrimaryClient = (value: Workspace | null) => {
    if (value !== null) {
      if (primaryClient?.workspace_id !== value.workspace_id) {
        setPrimaryClient(value);
        setGroupPeers([]);
      }
    } else {
      setPrimaryClient(null);
    }
  };
  useEffect(() => {
    setPeers([]);

    if (primaryClient !== null) {
      const primaryClientID = primaryClient?.workspace_id;
      setPeers(clients!.filter((client) => client.workspace_id !== primaryClientID));
    }
  }, [primaryClient, clients]);

  const [groupCountries, setGroupCountries] = useState<Country[] | undefined>([]);
  const [groupPeers, setGroupPeers] = useState<Workspace[] | undefined>([]);
  const [baseYear, setBaseYear] = useState<number | null>(currentYear);

  const handleSubmit = () => {
    setLoading(true);
    const peerGroup: Partial<PeerGroup> | null =
      mode === 'delete'
        ? null
        : {
            name: groupName,
            base_year: Number(baseYear),
            countries:
              groupCountries !== null && groupCountries !== undefined
                ? groupCountries.map((country) => country.label).join(',')
                : '',
            workspace_id: Number(primaryClient!.workspace_id),
            members:
              groupPeers !== null && groupPeers !== undefined
                ? groupPeers
                    .map((peer) => ({
                      workspace_id: Number(peer.workspace_id),
                      workspace_name: peer.workspace_name,
                    }))
                    .concat({
                      workspace_id: Number(primaryClient!.workspace_id),
                      workspace_name: primaryClient!.workspace_name,
                    })
                : [],
          };

    switch (mode) {
      case 'create':
        createPeerGroup(peerGroup!)
          .then((res: any) => {
            setLoading(false);
            if (res.error !== undefined) {
              handleOpenToast({ message: `Error saving peer group: ${res.error.data.message}`, severity: 'error' });
            } else {
              handleOpenToast({ message: 'Peer group created successfully', severity: 'success' });
              handleClose();
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            handleOpenToast({ message: 'Error saving peer group', severity: 'error' });
          });
        break;
      case 'edit':
        updatePeerGroup({ ...peerGroup, id: record!.id })
          .then((res: any) => {
            setLoading(false);
            if (res.error !== undefined) {
              handleOpenToast({ message: `Error editing peer group: ${res.error.data.message}`, severity: 'error' });
            } else {
              handleOpenToast({ message: 'Peer group edited successfully', severity: 'success' });
              handleClose();
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            handleOpenToast({ message: 'Error editing peer group', severity: 'error' });
          });
        break;
      case 'duplicate':
        createPeerGroup(peerGroup!)
          .then((res: any) => {
            setLoading(false);
            if (res.error !== undefined) {
              handleOpenToast({
                message: `Error duplicating peer group: ${res.error.data.message}`,
                severity: 'error',
              });
            } else {
              handleOpenToast({ message: 'Peer group duplicated successfully', severity: 'success' });
              handleClose();
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            handleOpenToast({ message: 'Error duplicating peer group', severity: 'error' });
          });
        break;
      case 'delete':
        deletePeerGroup(Number(record!.id))
          .then((res: any) => {
            setLoading(false);
            if (res.error !== undefined) {
              handleOpenToast({ message: `Error deleting peer group: ${res.error.data.message}`, severity: 'error' });
            } else {
              handleOpenToast({ message: 'Peer group deleted successfully', severity: 'success' });
              handleClose();
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            handleOpenToast({ message: 'Error deleting peer group', severity: 'error' });
          });
        break;
    }
  };

  const handleCancel = () => {
    handleReset();
    handleClose();
  };

  const handleReset = useCallback(() => {
    setGroupName('');
    setPrimaryClient(null);
    setGroupCountries([]);
    setGroupPeers([]);
    setBaseYear(currentYear);

    setPeers([]);
  }, [currentYear]);

  useEffect(() => {
    if (!open) {
      handleReset();
    }
  }, [open, handleReset]);

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      title={literals[mode].title}
      summary={literals[mode].summary}
      buttons={[
        { label: 'Cancel', action: handleCancel, variant: 'outlined' },
        {
          label: literals[mode].submit,
          action: handleSubmit,
          variant: 'filled',
          disabled:
            mode !== 'delete' &&
            (groupName.trim() === '' ||
              primaryClient === null ||
              groupCountries === undefined ||
              groupCountries.length === 0 ||
              groupPeers === undefined ||
              groupPeers.length === 0 ||
              baseYear === null ||
              (mode === 'duplicate' && groupName.trim().toLowerCase() === record?.name.trim().toLowerCase())),
        },
      ]}
    >
      {mode === 'delete' ? null : (
        <>
          <Grid item xs={12}>
            <TextField
              label="Enter Peer Group Name *"
              variant="outlined"
              fullWidth={true}
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
              error={mode === 'duplicate' && groupName.trim().toLowerCase() === record?.name.trim().toLowerCase()}
              helperText={
                mode === 'duplicate' && groupName.trim().toLowerCase() === record?.name.trim().toLowerCase()
                  ? 'This Peer Group Name is already in use. Please use a different name.'
                  : ''
              }
              InputProps={{
                endAdornment:
                  mode === 'duplicate' && groupName.trim().toLowerCase() === record?.name.trim().toLowerCase() ? (
                    <Error htmlColor={palette.red[500]} />
                  ) : null,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              disablePortal
              value={primaryClient}
              onChange={(e, selected) => {
                selectPrimaryClient(selected);
              }}
              options={clients!}
              getOptionLabel={(option) => option.workspace_name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.workspace_id}>
                    {option.workspace_name}
                  </li>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Select Primary Client *" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              value={groupCountries}
              options={countries}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.label}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                );
              }}
              renderTags={(tagValue) => `${tagValue.length} selected`}
              renderInput={(params) => <TextField {...params} label="Select Countries *" />}
              onChange={(event, selected, reason: any) => {
                if (event.type === 'click' || (event.type === 'keydown' && reason !== 'removeOption')) {
                  setGroupCountries(selected);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              value={groupPeers}
              options={peers}
              getOptionLabel={(option) => option.workspace_name}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option.workspace_id}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.workspace_name}
                  </li>
                );
              }}
              renderTags={(tagValue) => `${tagValue.length} selected`}
              renderInput={(params) => <TextField {...params} label="Select Peers *" />}
              onChange={(event, selected, reason: any) => {
                if (event.type === 'click' || (event.type === 'keydown' && reason !== 'removeOption')) {
                  setGroupPeers(selected);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              disablePortal
              value={baseYear}
              onChange={(e, selected) => {
                setBaseYear(selected);
              }}
              options={years}
              getOptionLabel={(option) => option.toString()}
              renderInput={(params) => <TextField {...params} label="Select Base Year *" />}
            />
          </Grid>
        </>
      )}
      {loading || apiLoading ? (
        <Backdrop open sx={{ zIndex: 1000 }}>
          <AppCircularProgress />
        </Backdrop>
      ) : null}
    </ModalWrapper>
  );
};

export default RecordModal;
