import { type SubmodulesEnum } from './SubmodulesEnum';
import { type UserInfo } from './User';

export interface Submodule {
  submodule_id: number;
  name: SubmodulesEnum;
  progress: number;
  status: SubmoduleStatus;
  end_timestamp: string;
  issues?: number;
  comments?: number;
  assigned_users: UserInfo[];
}

export interface SubmoduleWithDescription extends Submodule {
  description: string;
}

export enum SubmoduleStatus {
  AssignDataCollector = 'Assign Data Collector',
  AwaitingDataUpload = 'Awaiting Data Upload',
  ProcessingFile = 'Processing File',
  AwaitingIssueResolution = 'Awaiting Issue Resolution',
  DataUploadFinalized = 'Data Upload Finalized',
}
