import { BarChart, ChevronRight, ExpandMore, Search } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Modal,
  OutlinedInput,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AnalysisContext, methodEmitter } from '..';
import RedesignAppButton from '../../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../context/toastContext';
import {
  useGetOutlierAnalysisQuery,
  useGetPeerGroupQuery,
  useRecalculateKPIsMutation,
  useSaveOutlierAnalysisMutation,
} from '../../../../../../utils/redux/api';
import { type UpdatedDatapoint } from '../../../../../../utils/types/OutlierAnalysis';
import ChartModal from './ChartModal';

interface Props {
  module: string;
}

interface DatapointRecord {
  id: number;
  name: string;
  peers: Record<string | number, { id: number; value: number }>;
}

interface KPIRecord {
  id: number;
  name: string;
  datapoints: DatapointRecord[];
  peers: Record<string | number, { value: number; isOutlier: boolean; errors: string[] }>;
  average: number;
  median: number;
  quartile1: number;
  quartile3: number;
  peerCount: number;
  // peerCountCheckFailed: boolean;
}

interface DatapointToUpdate {
  oldValue: number;
  newValue: number | null;
}

const KPITable: React.FC<Props> = ({ module }) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleOpenToast } = useContext(ToastContext);
  const analysisContext = useContext(AnalysisContext);
  const [recalculateKPIs] = useRecalculateKPIsMutation();
  const [saveAnalysis] = useSaveOutlierAnalysisMutation();

  const StyledTableHead = styled(TableCell)(() => ({
    color: palette.green[700],
  }));

  const HighlightTableCell = styled(TableCell)(() => ({
    color: palette.green[500],
    backgroundColor: palette.green[50],
  }));

  const HighlightTableHead = styled(HighlightTableCell)(() => ({
    fontWeight: 700,
  }));

  const { peerGroupId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [peers, setPeers] = useState<Array<{ id: number; name: string; isPrimary: boolean }>>([]);
  const [expandedKPIs, setExpandedKPIs] = useState<number[]>([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const [chartXAxis, setChartXAxis] = useState<Record<string, any> | null>(null);
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartLabels, setChartLabels] = useState<Record<string | number, any>>([]);
  const [isChartModalOpen, setIsChartModalOpen] = useState(false);

  const [recalculating, setRecalculating] = useState<boolean>(false);
  const [recalculateData, setRecalculateData] = useState<KPIRecord[]>([]);
  const [datapointsToUpdate, setDatapointsToUpdate] = useState<Map<string, DatapointToUpdate>>(
    new Map<string, DatapointToUpdate>(),
  );
  const [updatedDatapoints, setUpdatedDatapoints] = useState<UpdatedDatapoint[]>([]);

  const { data: peerGroupData } = useGetPeerGroupQuery(Number(peerGroupId));
  useEffect(() => {
    if (peerGroupData !== null && peerGroupData !== undefined) {
      const peerGroupMembers = [];
      for (const member of peerGroupData.members) {
        peerGroupMembers.push({
          id: member.workspace_id,
          name: member.workspace_name,
          isPrimary: member.workspace_id === peerGroupData?.workspace_id,
        });
      }
      setPeers(peerGroupMembers);
    }
  }, [peerGroupData]);

  const sortedPeers = useMemo(() => peers.sort((a, b) => (a.isPrimary ? -1 : 1)), [peers]);

  const {
    data: outlierAnalysisData,
    isError: dataError,
    refetch: outlierAnalysisRefetch,
    isFetching: dataLoading,
  } = useGetOutlierAnalysisQuery({ peerGroupId: Number(peerGroupId), moduleName: encodeURIComponent(module) });
  useEffect(() => {
    if (dataLoading) {
      setLoading(true);
    } else {
      analysisContext.setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoading]);
  useEffect(() => {
    if (dataError) {
      handleOpenToast({ message: 'Unable to load module KPIs', severity: 'error' });
    }
  }, [dataError, handleOpenToast]);

  useEffect(() => {
    void outlierAnalysisRefetch();
  }, [location, outlierAnalysisRefetch]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate('/app/peer-groups');
    };

    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  const rawData: KPIRecord[] = useMemo(
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    () => {
      if (!dataLoading) {
        if (
          outlierAnalysisData !== null &&
          outlierAnalysisData !== undefined &&
          sortedPeers !== undefined &&
          sortedPeers.length > 0
        ) {
          setRecalculating(false);
          setDatapointsToUpdate(new Map<string, DatapointToUpdate>());
          setExpandedKPIs([]);

          const datapointMap = new Map<string, DatapointToUpdate>();
          const kpiData: KPIRecord[] = outlierAnalysisData.data.map((kpi) => {
            const kpiRecord: KPIRecord = {
              id: kpi.kpi_id,
              name: kpi.kpi_name,
              datapoints: kpi.datapoints.map((datapoint) => {
                return {
                  id: datapoint.dp_id,
                  name: datapoint.dp_name,
                  peers: {},
                };
              }),
              peers: {},
              average: kpi.average !== null ? Number(kpi.average.toFixed(2)) : 0,
              median: kpi.median !== null ? Number(kpi.median.toFixed(2)) : 0,
              quartile1: kpi.quartile_1 !== null ? Number(kpi.quartile_1.toFixed(2)) : 0,
              quartile3: kpi.quartile_3 !== null ? Number(kpi.quartile_3.toFixed(2)) : 0,
              peerCount: kpi.peer_count,
              // peerCountCheckFailed: kpi.peer_count < 3,
            };

            Object.keys(kpi.workspaces).forEach((peer) => {
              const peerId = sortedPeers.find((p) => p.name === peer)!.id;
              kpiRecord.peers[peerId] = {
                value: Number(kpi.workspaces[peer]?.toFixed(2)),
                isOutlier: kpi.is_outlier,
                errors: kpi.errors[peer],
              };
            });

            for (const datapoint of kpi.datapoints) {
              Object.keys(datapoint.workspaces).forEach((peer) => {
                const peerId = sortedPeers.find((p) => p.name === peer)!.id;
                kpiRecord.datapoints.find((dp) => dp.id === datapoint.dp_id)!.peers[peerId] = {
                  ...kpiRecord.datapoints.find((dp) => dp.id === datapoint.dp_id)!.peers[peerId],
                  value: Number(datapoint.workspaces[peer]?.toFixed(2)),
                };
                datapointMap.set(JSON.stringify([peerId, datapoint.dp_id]), {
                  oldValue: datapoint.workspaces[peer],
                  newValue: null,
                });
              });
            }

            return kpiRecord;
          });
          setDatapointsToUpdate(datapointMap);

          setLoading(false);
          return kpiData;
        } else {
          setLoading(false);
          return [];
        }
      }
      return [];
    },
    [dataLoading, outlierAnalysisData, sortedPeers],
  );

  const kpiData = React.useMemo(
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    () => {
      if (loading) {
        return [];
      } else {
        if (recalculating) {
          return [...recalculateData].filter(
            (kpi) =>
              kpi.name.toLowerCase().includes(search.toLowerCase()) ||
              kpi.datapoints.some((datapoint) => datapoint.name.toLowerCase().includes(search.toLowerCase())),
          );
        } else {
          return [...rawData].filter(
            (kpi) =>
              kpi.name.toLowerCase().includes(search.toLowerCase()) ||
              kpi.datapoints.some((datapoint) => datapoint.name.toLowerCase().includes(search.toLowerCase())),
          );
        }
      }
    },
    [loading, recalculating, rawData, recalculateData, search],
  );

  const setNewDatapointValue = function (peerId: number, mappingId: number, value: number) {
    analysisContext.setIsEditing(true);
    const currentDatapoint = datapointsToUpdate.get(JSON.stringify([peerId, mappingId]));
    datapointsToUpdate.set(JSON.stringify([peerId, mappingId]), {
      oldValue: currentDatapoint!.oldValue,
      newValue: value,
    });
  };

  const checkDatapointsToUpdate = (): UpdatedDatapoint[] => {
    const datapoints: UpdatedDatapoint[] = [];
    datapointsToUpdate.forEach((value, key) => {
      if (value.newValue !== null && value.newValue !== value.oldValue) {
        datapoints.push({
          workspace_id: JSON.parse(key)[0],
          data_point_id: JSON.parse(key)[1],
          value: value.newValue,
        });
      }
    });

    if (datapoints.length > 0) {
      setUpdatedDatapoints(datapoints);
    } else {
      handleOpenToast({ message: 'No updated datapoint values found', severity: 'warning' });
    }
    return datapoints;
  };

  const handleRecalculate = (dpChanges: UpdatedDatapoint[]) => {
    setLoading(true);

    recalculateKPIs({
      peerGroupId: Number(peerGroupId),
      moduleName: module,
      body: {
        primary_player: peerGroupData?.workspace_id ?? 0,
        dp_changes: dpChanges,
        include_workspaces: peers.map((peer) => peer.id),
      },
    })
      .then((res) => {
        setIsConfirmationModalOpen(false);
        if (res.error !== undefined) {
          handleOpenToast({ message: 'Error recalculating KPIs', severity: 'error' });
          setLoading(false);
        } else {
          const recalculationData = res.data;
          setRecalculateData(
            recalculationData.data.map((kpi) => {
              const kpiRecord: KPIRecord = {
                id: kpi.kpi_id,
                name: kpi.kpi_name,
                datapoints: kpi.datapoints.map((datapoint) => {
                  return {
                    id: datapoint.dp_id,
                    name: datapoint.dp_name,
                    peers: {},
                  };
                }),
                peers: {},
                average: kpi.average !== null ? Number(kpi.average.toFixed(2)) : 0,
                median: kpi.median !== null ? Number(kpi.median.toFixed(2)) : 0,
                quartile1: kpi.quartile_1 !== null ? Number(kpi.quartile_1.toFixed(2)) : 0,
                quartile3: kpi.quartile_3 !== null ? Number(kpi.quartile_3.toFixed(2)) : 0,
                peerCount: kpi.peer_count,
                // peerCountCheckFailed: kpi.peer_count < 3,
              };

              Object.keys(kpi.workspaces).forEach((peer) => {
                const peerId = sortedPeers.find((p) => p.name === peer)!.id;
                kpiRecord.peers[peerId] = {
                  value: Number(kpi.workspaces[peer]?.toFixed(2)),
                  isOutlier: kpi.is_outlier,
                  errors: kpi.errors[peer],
                };
              });

              for (const datapoint of kpi.datapoints) {
                Object.keys(datapoint.workspaces).forEach((peer) => {
                  const peerId = sortedPeers.find((p) => p.name === peer)!.id;
                  kpiRecord.datapoints.find((dp) => dp.id === datapoint.dp_id)!.peers[peerId] = {
                    ...kpiRecord.datapoints.find((dp) => dp.id === datapoint.dp_id)!.peers[peerId],
                    value: Number(datapoint.workspaces[peer]?.toFixed(2)),
                  };
                });
              }

              return kpiRecord;
            }),
          );

          setLoading(false);
          handleOpenToast({ message: 'KPIs recalculated successfully', severity: 'success' });
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setIsConfirmationModalOpen(false);
        handleOpenToast({ message: 'Error recalculating KPIs', severity: 'error' });
      });
  };

  const confirmSave = function () {
    setRecalculating(false);
    setUpdatedDatapoints([]);
    if (checkDatapointsToUpdate().length > 0) {
      setIsConfirmationModalOpen(true);
    }
  };

  const handleSave = function () {
    setModalLoading(true);

    saveAnalysis({
      peer_group_id: Number(peerGroupId),
      module_name: module,
      primary_player: peerGroupData?.workspace_id ?? 0,
      dp_changes: updatedDatapoints,
      include_workspaces: peers.map((peer) => peer.id),
    })
      .then((res: any) => {
        setModalLoading(false);
        setIsConfirmationModalOpen(false);
        if (res.error !== undefined) {
          handleOpenToast({ message: `Error saving analysis: ${res.error.data.message}`, severity: 'error' });
        } else {
          handleOpenToast({ message: 'Analysis saved successfully', severity: 'success' });
          void outlierAnalysisRefetch();
        }
      })
      .catch((err) => {
        console.error(err);
        setModalLoading(false);
        setIsConfirmationModalOpen(false);
        handleOpenToast({ message: 'Error saving analysis', severity: 'error' });
      });
  };

  useEffect(() => {
    const recalculateKPIs = () => {
      setRecalculating(true);
      const datapointsToUpdate = checkDatapointsToUpdate();
      if (datapointsToUpdate.length > 0) {
        handleRecalculate(datapointsToUpdate);
      }
    };

    methodEmitter.on('recalculateKPIs', recalculateKPIs);

    return () => {
      methodEmitter.off('recalculateKPIs', recalculateKPIs);
    };
  });

  const getCellColor = function (error: string[]) {
    if (error.length === 1) {
      return {
        NullCheckedFailed: '#FFE1A8',
        ZeroCheckedFailed: '#A0D3EC',
        RangeCheckFailed: '#FFA793',
        PeerCountCheckFailed: '#AD9FF3',
        MultipleErrors: '#D682AC',
        ValueMedianCheck: '#FF4D6B',
        NegativeValueErrors: '#93FFF1',
      }[error[0].toString()];
    }

    return '#D682AC'; // multiple errors
  };

  const getTooltipMessage = function (error: string[]) {
    const messages: Record<string, string> = {
      NullCheckedFailed: 'Null Check Failed',
      ZeroCheckedFailed: 'Zero Check Failed',
      RangeCheckFailed: 'Range Check Failed',
      PeerCountCheckFailed: 'Peer Count Check Failed',
      MultipleErrors: 'Multiple Errors',
      ValueMedianCheck: 'Value Median Check Failed',
      NegativeValueErrors: 'Negative Value Errors',
    };

    return error.map((err) => messages[err]).join('\n');
  };

  const expandKPI = function (id: number) {
    setExpandedKPIs((prev) => {
      if (prev.includes(id)) {
        return prev.filter((kpiId) => kpiId !== id);
      }
      return [...prev, id];
    });
  };

  const displayKPIChart = function (kpi: KPIRecord) {
    setChartXAxis([{ scaleType: 'band', dataKey: 'kpi' }]);

    const kpiData: Record<string | number, any> = {};
    const labels: Record<string | number, any> = {};
    for (const peer of peers) {
      kpiData[peer.id] = kpi.peers[peer.id].value;
      labels[peer.id] = peer.name;
    }
    kpiData.kpi = kpi.name;
    setChartData([kpiData]);
    setChartLabels(labels);

    setIsChartModalOpen(true);
  };

  const displayDatapointChart = function (datapoint: DatapointRecord) {
    setChartXAxis([{ scaleType: 'band', dataKey: 'datapoint' }]);

    const data: Record<string | number, any> = {};
    const labels: Record<string | number, any> = {};
    for (const peer of peers) {
      data[peer.id] = datapoint.peers[peer.id].value;
      labels[peer.id] = peer.name;
    }
    data.datapoint = datapoint.name;
    setChartData([data]);
    setChartLabels(labels);

    setIsChartModalOpen(true);
  };

  return (
    <Box display="flex" flexDirection="column" gap={4} sx={{ padding: '24px 32px' }}>
      {(loading || dataLoading) && !dataError ? (
        <Backdrop open>
          <AppCircularProgress />
        </Backdrop>
      ) : dataError ? (
        <Typography variant="bodySmall" sx={{ textAlign: 'center' }}>
          No content found
        </Typography>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex">
              <FormControl fullWidth sx={{ width: '280px' }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search for a KPI or Datapoint</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  endAdornment={
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  }
                  label="Search for a KPI or Datapoint"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </FormControl>
            </Box>

            <TableContainer sx={{ height: '45vh', overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ whiteSpace: 'nowrap' }}>
                    <HighlightTableHead>KPI</HighlightTableHead>
                    {sortedPeers.map((peer) => (
                      <StyledTableHead key={peer.id} sx={{ textAlign: 'center' }}>
                        {peer.isPrimary ? 'Primary Client' : peer.name}
                      </StyledTableHead>
                    ))}
                    <HighlightTableHead sx={{ textAlign: 'center' }}>Quartile 1</HighlightTableHead>
                    <HighlightTableHead sx={{ textAlign: 'center' }}>Average</HighlightTableHead>
                    <HighlightTableHead sx={{ textAlign: 'center' }}>Median</HighlightTableHead>
                    <HighlightTableHead sx={{ textAlign: 'center' }}>Quartile 3</HighlightTableHead>
                    <HighlightTableHead sx={{ textAlign: 'center' }}>Peer Count</HighlightTableHead>
                    <StyledTableHead width="48px">Chart</StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {kpiData.map((kpi) => (
                    <React.Fragment key={kpi.id}>
                      <TableRow key={kpi.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <HighlightTableCell sx={{ whiteSpace: 'nowrap' }}>
                          <Box
                            display="flex"
                            gap="10px"
                            alignItems="center"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              expandKPI(kpi.id);
                            }}
                          >
                            <IconButton size="small" sx={{ padding: 0 }}>
                              {!expandedKPIs.includes(kpi.id) ? (
                                <ChevronRight htmlColor={palette.green[500]} />
                              ) : (
                                <ExpandMore htmlColor={palette.green[500]} />
                              )}
                            </IconButton>
                            <span>{kpi.name}</span>
                          </Box>
                        </HighlightTableCell>
                        {sortedPeers.map((peer) => (
                          <TableCell
                            key={peer.id}
                            sx={{
                              backgroundColor:
                                kpi.peers[peer.id]?.errors.length > 0
                                  ? getCellColor(kpi.peers[peer.id]?.errors)
                                  : null,
                              textAlign: 'center',
                            }}
                          >
                            {kpi.peers[peer.id] !== undefined ? (
                              kpi.peers[peer.id].errors.length > 0 ? (
                                <Tooltip
                                  title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                      {getTooltipMessage(kpi.peers[peer.id]?.errors ?? [])}
                                    </div>
                                  }
                                >
                                  <div>{kpi.peers[peer.id].value ?? '-'}</div>
                                </Tooltip>
                              ) : (
                                <div>{kpi.peers[peer.id].value ?? '-'}</div>
                              )
                            ) : (
                              '-'
                            )}
                          </TableCell>
                        ))}
                        <HighlightTableCell sx={{ textAlign: 'center' }}>{kpi.quartile1}</HighlightTableCell>
                        <HighlightTableCell sx={{ textAlign: 'center' }}>{kpi.average}</HighlightTableCell>
                        <HighlightTableCell sx={{ textAlign: 'center' }}>{kpi.median}</HighlightTableCell>
                        <HighlightTableCell sx={{ textAlign: 'center' }}>{kpi.quartile3}</HighlightTableCell>
                        <HighlightTableCell
                          sx={{
                            textAlign: 'center',
                            // backgroundColor: kpi.peerCountCheckFailed ? '#AD9FF3' : null,
                            // color: kpi.peerCountCheckFailed ? palette.gray[500] : null,
                          }}
                        >
                          {kpi.peerCount}
                        </HighlightTableCell>
                        <TableCell>
                          <Box display="flex" justifyContent="space-between">
                            <Divider orientation="vertical" flexItem color={palette.gray[300]} />
                            <IconButton
                              size="small"
                              sx={{ padding: 0 }}
                              onClick={() => {
                                displayKPIChart(kpi);
                              }}
                            >
                              <BarChart htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: '16px' }} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {expandedKPIs.includes(kpi.id)
                        ? kpi.datapoints.map((datapoint: DatapointRecord, idx) => (
                            <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell sx={{ paddingLeft: '56px' }}>{datapoint.name}</TableCell>
                              {sortedPeers.map((peer) => (
                                <TableCell key={peer.id} sx={{ textAlign: 'center' }}>
                                  {datapoint.peers[peer.id] !== undefined ? (
                                    <InputBase
                                      id={`datapoint-field-$${kpi.id}-${datapoint.id}`}
                                      type="number"
                                      autoComplete="off"
                                      defaultValue={
                                        datapointsToUpdate.get(JSON.stringify([peer.id, datapoint.id]))!.newValue ??
                                        datapointsToUpdate.get(JSON.stringify([peer.id, datapoint.id]))!.oldValue
                                      }
                                      onChange={(e) => {
                                        setNewDatapointValue(peer.id, datapoint.id, Number(e.target.value));
                                      }}
                                      sx={{ width: '100%' }}
                                      inputProps={{ style: { textAlign: 'center' } }}
                                    />
                                  ) : (
                                    '-'
                                  )}
                                </TableCell>
                              ))}
                              <HighlightTableCell colSpan={5}></HighlightTableCell>
                              <TableCell>
                                <Box display="flex" justifyContent="space-between">
                                  <Divider orientation="vertical" flexItem color={palette.gray[300]} />
                                  <Tooltip title="View Chart">
                                    <span>
                                      <IconButton
                                        size="small"
                                        sx={{ padding: 0 }}
                                        onClick={() => {
                                          displayDatapointChart(datapoint);
                                        }}
                                      >
                                        <BarChart
                                          htmlColor={palette.green[500]}
                                          sx={{ margin: '8px', fontSize: '16px' }}
                                        />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <ChartModal
              open={isChartModalOpen}
              labels={chartLabels}
              dataset={chartData}
              xAxis={chartXAxis}
              chartSetting={{ height: 500 }}
              handleClose={() => {
                setIsChartModalOpen(false);
              }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <RedesignAppButton appVariant="filled" onClick={confirmSave}>
              Save and Close
            </RedesignAppButton>
          </Box>

          <Modal
            open={isConfirmationModalOpen}
            onClose={() => {
              setIsConfirmationModalOpen(false);
            }}
          >
            <>
              <Box
                position="absolute"
                bgcolor="white"
                sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                p={2}
                display="flex"
                flexDirection="column"
                borderRadius={1}
                gap={3}
                width="580px"
              >
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="titleMedium">Save Outlier Analysis</Typography>
                  <Typography variant="bodySmall">Do you wish to mark this analysis as complete?</Typography>
                </Box>

                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <RedesignAppButton
                    appVariant="outlined"
                    onClick={() => {
                      setIsConfirmationModalOpen(false);
                    }}
                  >
                    Discard
                  </RedesignAppButton>
                  <RedesignAppButton appVariant="filled" onClick={handleSave}>
                    Save and Close
                  </RedesignAppButton>
                </Box>
              </Box>
              {modalLoading ? (
                <Backdrop open sx={{ zIndex: 1000 }}>
                  <AppCircularProgress />
                </Backdrop>
              ) : null}
            </>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default KPITable;
