import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { ProductAndSegmentTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const RevenuesByProduct: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, ProductAndSegmentTables.RevenuesByProduct),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse, isLoading: isLoadingDataTable } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: ProductAndSegmentTables.RevenuesByProduct,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Deposits', editable: false, indentLevel: 1 },
    { label: 'Checking account', editable: true, indentLevel: 2 },
    { label: 'Savings account', editable: true, indentLevel: 2 },
    { label: 'of which money market', editable: true, indentLevel: 3 },
    { label: 'Term deposit', editable: true, indentLevel: 2 },
    { label: 'Credit', editable: false, indentLevel: 1 },
    { label: 'Overdrafts', editable: true, indentLevel: 2 },
    { label: 'Credit cards', editable: true, indentLevel: 2 },
    { label: 'Unsecured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Secured consumer loans', editable: true, indentLevel: 2 },
    { label: 'Mortgage total', editable: true, indentLevel: 2 },
    { label: 'of which new mortgages', editable: true, indentLevel: 3 },
    { label: 'of which mortage refinance', editable: true, indentLevel: 3 },
    { label: 'Home equity', editable: true, indentLevel: 2 },
    { label: 'Investments', editable: true, indentLevel: 1 },
    { label: 'Insurance', editable: true, indentLevel: 1 },
    { label: 'Other', editable: true, indentLevel: 1 },
    { label: 'Total', editable: false, indentLevel: 1 },
  ]);

  const columns = useRef<Column[]>([
    { key: 'A', label: 'Gross interest income/expense ($)', editable: true },
    { key: 'B', label: 'Funds transfer pricing (FTP)', editable: true },
    { key: 'C', label: 'Net interest income ($)', editable: true },
    { key: 'D', label: 'Net fee and other income ($)', editable: true },
    { key: 'E', label: 'Total Revenue ($)', editable: false },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            table: ProductAndSegmentTables.RevenuesByProduct,
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
            tabKey,
          },
          columns: ['A', 'B', 'C', 'D', 'E'],
          rowCount: 18,
          totalColumns: [
            'A1',
            'A6',
            'C1',
            'C6',
            'C18',
            'D1',
            'D6',
            'D18',
            'E1',
            'E2',
            'E3',
            'E4',
            'E5',
            'E6',
            'E7',
            'E8',
            'E9',
            'E10',
            'E11',
            'E12',
            'E13',
            'E14',
            'E15',
            'E16',
            'E17',
            'E18',
          ],
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, fetchDataTableCommentThreads, dispatch, tableSubmoduleId, fetchDataTableIssues]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const a1 = formatNumber(sumValues(['A2', 'A3', 'A5'], values));
    const c1 = formatNumber(sumValues(['C2', 'C3', 'C5'], values));
    const d1 = formatNumber(sumValues(['D2', 'D3', 'D5'], values));
    const a6 = formatNumber(sumValues(['A7', 'A8', 'A9', 'A10', 'A11'], values) + (values.A14 ?? 0));
    const c6 = formatNumber(sumValues(['C7', 'C8', 'C9', 'C10', 'C11'], values) + (values.C14 ?? 0));
    const d6 = formatNumber(sumValues(['D7', 'D8', 'D9', 'D10', 'D11'], values) + (values.D14 ?? 0));
    const c18 = formatNumber(c1 + c6);
    const d18 = formatNumber(d1 + d6 + sumValues(['D15', 'D16', 'D17'], values));
    const e1 = formatNumber(c1 + d1);
    const e2 = formatNumber(sumValues(['C2', 'D2'], values));
    const e3 = formatNumber(sumValues(['C3', 'D3'], values));
    const e4 = formatNumber(sumValues(['C4', 'D4'], values));
    const e5 = formatNumber(sumValues(['C5', 'D5'], values));
    const e6 = formatNumber(c6 + d6);
    const e7 = formatNumber(sumValues(['C7', 'D7'], values));
    const e8 = formatNumber(sumValues(['C8', 'D8'], values));
    const e9 = formatNumber(sumValues(['C9', 'D9'], values));
    const e10 = formatNumber(sumValues(['C10', 'D10'], values));
    const e11 = formatNumber(sumValues(['C11', 'D11'], values));
    const e12 = formatNumber(sumValues(['C12', 'D12'], values));
    const e13 = formatNumber(sumValues(['C13', 'D13'], values));
    const e14 = formatNumber(sumValues(['C14', 'D14'], values));
    const e15 = formatNumber(sumValues(['C15', 'D15'], values));
    const e16 = formatNumber(sumValues(['C16', 'D16'], values));
    const e17 = formatNumber(sumValues(['C17', 'D17'], values));
    const e18 = formatNumber(c18 + d18);

    return {
      ...values,
      A1: a1,
      A6: a6,
      C1: c1,
      C6: c6,
      C18: c18,
      D1: d1,
      D6: d6,
      D18: d18,
      E1: e1,
      E2: e2,
      E3: e3,
      E4: e4,
      E5: e5,
      E6: e6,
      E7: e7,
      E8: e8,
      E9: e9,
      E10: e10,
      E11: e11,
      E12: e12,
      E13: e13,
      E14: e14,
      E15: e15,
      E16: e16,
      E17: e17,
      E18: e18,
    };
  };

  const isCellDisabled = (category: Category, column: Column) => {
    if (category.label === 'Deposits' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Credit' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Overdrafts' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Secured consumer loans' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Investments' && column.label === 'Net interest income ($)') {
      return true;
    }
    if (category.label === 'Investments' && column.label === 'Gross interest income/expense ($)') {
      return true;
    }
    if (category.label === 'Investments' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Insurance' && column.label === 'Net interest income ($)') {
      return true;
    }
    if (category.label === 'Insurance' && column.label === 'Gross interest income/expense ($)') {
      return true;
    }
    if (category.label === 'Insurance' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Gross interest income/expense ($)') {
      return true;
    }
    if (category.label === 'Other' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Gross interest income/expense ($)') {
      return true;
    }
    if (category.label === 'Total' && column.label === 'Funds transfer pricing (FTP)') {
      return true;
    }
    return false;
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);

    dispatch(
      updateDataTable({
        dataTable: { tabKey, table: ProductAndSegmentTables.RevenuesByProduct, values: updatedValues },
        columns: ['A', 'B', 'C', 'D', 'E'],
        rowCount: 18,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getRowStyle={(category) => {
            if (category.label === 'Total') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          getColumnStyle={(column) => {
            if (column.label === 'Total Revenue ($)') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          isCellDisabled={isCellDisabled}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default RevenuesByProduct;
