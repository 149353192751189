import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { OnlineAndMobileCustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { handleOpenComments } from '../../utils/handleOpenComments';

const ChatbotUsage: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable1 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, OnlineAndMobileCustomersTables.ChatbotUsageTable1),
  );
  const dataTable2 = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, OnlineAndMobileCustomersTables.ChatbotUsageTable2),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse1, isLoading: isLoadingDataTable1 } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: OnlineAndMobileCustomersTables.ChatbotUsageTable1,
  });
  const { data: dataTableResponse2, isLoading: isLoadingDataTable2 } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: OnlineAndMobileCustomersTables.ChatbotUsageTable2,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const table1Categories = useRef<Category[]>([
    { label: 'Number of WebChat sessions', editable: true, indentLevel: 1 },
    { label: 'of which sessions covered entirely by Chatbots', editable: true, indentLevel: 2 },
    { label: 'of which sessions which requires human intervention', editable: true, indentLevel: 2 },
    { label: 'Number of FTEs handling webchat', editable: true, indentLevel: 1 },
  ]);
  const table1Columns = useRef<Column[]>([{ key: 'A', label: 'Total (#)', editable: true }]);

  const table2Categories = useRef<Category[]>([
    { label: 'Customer touchpoints in chatbot', editable: true, indentLevel: 1 },
    { label: 'Number of complaints or issues reported', editable: true, indentLevel: 2 },
    { label: 'Number of request of product information', editable: true, indentLevel: 2 },
    { label: 'Number of request to perform an action', editable: true, indentLevel: 2 },
    { label: 'Other', editable: true, indentLevel: 2 },
  ]);
  const table2Columns = useRef<Column[]>([{ key: 'A', label: 'Total (#)', editable: true }]);

  useEffect(() => {
    if (!isLoadingDataTable1) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse1?.id ?? 0,
            commentCount: dataTableResponse1?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: OnlineAndMobileCustomersTables.ChatbotUsageTable1,
            data: dataTableResponse1?.data ?? [],
            columns: dataTableResponse1?.columns ?? [],
            rows: dataTableResponse1?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 4,
        }),
      );
    }

    dataTableResponse1?.rows.forEach((rowName, index) => {
      table1Categories.current[index].rowName = rowName;
    });

    dataTableResponse1?.columns?.forEach((columnName, index) => {
      table1Columns.current[index].columnName = columnName;
    });

    if (!isLoadingDataTable2) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse2?.id ?? 0,
            commentCount: dataTableResponse2?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: OnlineAndMobileCustomersTables.ChatbotUsageTable2,
            data: dataTableResponse2?.data ?? [],
            columns: dataTableResponse2?.columns ?? [],
            rows: dataTableResponse2?.rows ?? [],
          },
          columns: ['A'],
          rowCount: 5,
        }),
      );

      dataTableResponse2?.rows.forEach((rowName, index) => {
        table2Categories.current[index].rowName = rowName;
      });

      dataTableResponse2?.columns?.forEach((columnName, index) => {
        table2Columns.current[index].columnName = columnName;
      });
    }
  }, [
    dispatch,
    dataTableResponse1,
    dataTableResponse2,
    isLoadingDataTable1,
    isLoadingDataTable2,
    tableSubmoduleId,
    tabKey,
  ]);

  useEffect(() => {
    if (dataTable1 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable1.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable1.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable1, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable2 === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable2.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable2.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable2, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const handleClick = (cellId: string, column: string, row: string, dataTableId: number | undefined) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTableId, { cellId, column, row });
    }
  };

  const handleValueChangeTable1 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: OnlineAndMobileCustomersTables.ChatbotUsageTable1,
          values: {
            ...dataTable1?.values,
            [cellId]: newValue,
          },
        },
        columns: ['A'],
        rowCount: 4,
      }),
    );
  };
  const handleValueChangeTable2 = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: OnlineAndMobileCustomersTables.ChatbotUsageTable2,
          values: {
            ...dataTable2?.values,
            [cellId]: newValue,
          },
        },
        columns: ['A'],
        rowCount: 5,
      }),
    );
  };

  const date = new Date(workspace!.end_of_period);
  date.setDate(date.getDate() - 90);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box>
        <TableComponent
          categories={table1Categories.current}
          columns={table1Columns.current}
          values={dataTable1?.values ?? {}}
          onValueChange={handleValueChangeTable1}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable1?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable1?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable1?.issues ?? []}
        />
      </Box>
      <Box>
        <TableComponent
          categories={table2Categories.current}
          columns={table2Columns.current}
          values={dataTable2?.values ?? {}}
          onValueChange={handleValueChangeTable2}
          onClickCell={(cellId, column, row) => {
            handleClick(cellId, column, row, dataTable2?.id);
          }}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable2?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable1?.commentThreads)}
          issues={dataTable2?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default ChatbotUsage;
