import { AddCircle } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import AppButton from '../../../../components/materials/actions/AppButton';
import { useGetAllWorkspacesQuery } from '../../../../utils/redux/api';
import { type PeerGroup } from '../../../../utils/types/PeerGroup';
import PeerGroupList from './PeerGroupList';
import DownloadModal from './components/DownloadModal';
import RecordModal from './components/RecordModal';

const PeerGroups: React.FC = () => {
  const { palette } = useTheme();
  const [refetch, setRefetch] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const { data: allWorkspaces } = useGetAllWorkspacesQuery(null);
  const [selectedRecord, setSelectedRecord] = useState<PeerGroup | null>(null);

  return (
    <>
      <Box sx={{ display: 'flex', gap: '16px', flexDirection: 'column', mb: '16px' }}>
        <Box
          sx={{
            border: '1px',
            borderRadius: '8px',
            padding: '20px 32px',
            gap: '16px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray.white,
            borderColor: palette.gray[300],
          }}
          border={1}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" gap="16px">
            <Typography variant="displaySmall">Peer Groups</Typography>
            <AppButton
              startIcon={<AddCircle />}
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: palette.green[500],
                borderColor: palette.green[500],
                backgroundColor: 'transparent',
                borderRadius: '8px',
                padding: '0 14px',
                '&:hover, &:active, &:focus': {
                  backgroundColor: palette.green[500],
                  color: palette.gray.white,
                },
              }}
              onClick={() => {
                setIsCreateModalOpen(true);
              }}
            >
              <Typography variant="buttonMedium">New Peer Group</Typography>
            </AppButton>

            <RecordModal
              mode="create"
              open={isCreateModalOpen}
              clients={allWorkspaces ?? []}
              handleClose={() => {
                setIsCreateModalOpen(false);
                setRefetch(true);
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <PeerGroupList
          shouldRefetch={refetch}
          setRefetch={setRefetch}
          setSelectedRecord={setSelectedRecord}
          setIsEditModalOpen={setIsEditModalOpen}
          setIsDuplicateModalOpen={setIsDuplicateModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setIsDownloadModalOpen={setIsDownloadModalOpen}
        />

        <RecordModal
          mode="edit"
          record={selectedRecord}
          open={isEditModalOpen}
          clients={allWorkspaces ?? []}
          handleClose={() => {
            setIsEditModalOpen(false);
            setRefetch(true);
          }}
        />

        <RecordModal
          mode="duplicate"
          record={selectedRecord}
          open={isDuplicateModalOpen}
          clients={allWorkspaces ?? []}
          handleClose={() => {
            setIsDuplicateModalOpen(false);
            setRefetch(true);
          }}
        />

        <RecordModal
          mode="delete"
          record={selectedRecord}
          open={isDeleteModalOpen}
          handleClose={() => {
            setIsDeleteModalOpen(false);
            setRefetch(true);
          }}
        />

        <DownloadModal
          open={isDownloadModalOpen}
          record={selectedRecord}
          handleClose={() => {
            setIsDownloadModalOpen(false);
          }}
        />
      </Box>
    </>
  );
};

export default PeerGroups;
