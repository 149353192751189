import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { type Issue } from '../../../utils/types/Issue';
import TableCellComponent from './TableCellComponent';

export interface Column {
  key: string;
  label: string;
  editable: boolean;
  columnName?: string;
}

export interface Category {
  label: string;
  editable?: boolean;
  indentLevel: number;
  rowName?: string;
}

interface TableComponentProps {
  categories: Category[];
  columns: Column[];
  values: Record<string, number | null>;
  onValueChange: (cellId: string, newValue: number | null) => void;
  onClickCell?: (cellId: string, column: string, row: string) => void;
  onRightClickCell?: (cellId: string, column: string, row: string) => void;
  banner: boolean;
  bannerValue: string;
  getRowStyle?: (category: Category) => React.CSSProperties | undefined;
  getColumnStyle?: (column: Column) => React.CSSProperties | undefined;
  isCellDisabled?: (category: Category, column: Column) => boolean;
  customTextFieldStyle?: (category: Category, column: Column) => React.CSSProperties;
  cellsWithCommentsSet: Set<string>;
  issues: Issue[];
}

const TableComponent: React.FC<TableComponentProps> = ({
  categories,
  columns,
  values,
  onValueChange,
  onClickCell,
  onRightClickCell,
  banner,
  bannerValue,
  getRowStyle,
  getColumnStyle,
  isCellDisabled,
  customTextFieldStyle,
  cellsWithCommentsSet,
  issues,
}) => {
  const { palette } = useTheme();
  const getIndent = (indentLevel: number) => {
    return {
      paddingLeft: `${indentLevel * 20}px`,
    };
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          '& th': {
            textAlign: 'center',
          },
          height: '100%',
        }}
      >
        <TableHead>
          {banner && (
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell colSpan={8} sx={{ backgroundColor: palette.gray[250] }}>
                <Typography variant="labelMedium" sx={{ color: palette.gray[500] }}>
                  {bannerValue}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={2} />
            {columns.map((column) => (
              <TableCell key={column.key + column.columnName}>
                <Typography variant="bodySmall" color={palette.gray[500]}>
                  {column.key}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell sx={{ backgroundColor: palette.green[300] }}></TableCell>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{ backgroundColor: palette.green[200], ...(getColumnStyle?.(column) ?? {}) }}
              >
                <Typography variant="labelLarge" sx={{ whiteSpace: 'pre-line' }}>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => (
            <TableRow key={index}>
              <TableCell sx={{ color: palette.gray[450] }}>{index + 1}</TableCell>
              <TableCell
                style={{
                  ...{ backgroundColor: palette.green[200] },
                  ...getIndent(category.indentLevel),
                  ...(getRowStyle?.(category) ?? {}),
                }}
              >
                <Typography variant="labelLarge">{category.label}</Typography>
              </TableCell>
              {columns.map((column) => (
                <TableCellComponent
                  key={`${index}${column.label}${category.label}`}
                  category={category}
                  column={column}
                  index={index}
                  isCellDisabled={isCellDisabled}
                  onValueChange={onValueChange}
                  values={values}
                  customTextFieldStyle={customTextFieldStyle}
                  onClickCell={onClickCell}
                  onRightClickCell={onRightClickCell}
                  hasComment={cellsWithCommentsSet.has(`${column.key}${index + 1}`)}
                  issues={issues.filter(
                    (issue) => issue.column === column.columnName && issue.row === category.rowName,
                  )}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;
