import { Box, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import RedesignAppButton from '../../../../../components/materials/actions/RedesignAppButton';

interface Props {
  open: boolean;
  handleClose: () => void;
  wide?: boolean;
  title: string;
  buttons: Array<{
    label: string | JSX.Element;
    action: () => void;
    variant: 'filled' | 'outlined';
    disabled?: boolean;
  }>;
  summary?: string;
  children?: React.ReactNode;
}

const ModalWrapper: React.FC<Props> = ({
  open,
  handleClose,
  wide = false,
  title,
  summary,
  buttons = [],
  children,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        position="absolute"
        bgcolor="white"
        sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        p={2}
        display="flex"
        flexDirection="column"
        borderRadius={1}
        gap={3}
        width={wide ? '680px' : '550px'}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="titleMedium">{title}</Typography>
          {summary !== null && summary !== undefined ? <Typography variant="bodySmall">{summary}</Typography> : null}
        </Box>
        <Grid container spacing={2}>
          {children}
        </Grid>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          {buttons.map((button, idx) => {
            return (
              <RedesignAppButton
                key={idx}
                appVariant={button.variant}
                onClick={button.action}
                disabled={button.disabled}
              >
                {button.label}
              </RedesignAppButton>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalWrapper;
