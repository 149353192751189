import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { ContactCenterTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { handleOpenComments } from '../../utils/handleOpenComments';

const CallCenterCustomerActivity: React.FC = () => {
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, ContactCenterTables.CustomerActivityInCallCenter),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse, isLoading: isLoadingDataTable } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: ContactCenterTables.CustomerActivityInCallCenter,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Call center (call)', editable: true, indentLevel: 1 },
    { label: 'Remote advisor (video chat)', editable: true, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Number of customers who made a call in 90 days (#)', editable: true },
    { key: 'B', label: 'Number of customers who made a call in 30 days (#)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: ContactCenterTables.CustomerActivityInCallCenter,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B'],
          rowCount: 2,
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tableSubmoduleId, tabKey]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: ContactCenterTables.CustomerActivityInCallCenter,
          values: { ...dataTable?.values, [cellId]: newValue },
        },
        columns: ['A', 'B'],
        rowCount: 2,
      }),
    );
  };

  const date1 = new Date(workspace!.end_of_period);
  date1.setDate(date1.getDate() - 90);
  const date2 = new Date(workspace!.end_of_period);
  date2.setDate(date2.getDate() - 30);

  return (
    <Box>
      <TableComponent
        categories={categories.current}
        columns={columns.current}
        values={dataTable?.values ?? {}}
        onValueChange={handleValueChange}
        onClickCell={handleClick}
        onRightClickCell={(cellId, column, row) => {
          handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
        }}
        banner={true}
        bannerValue={`${date1.getFullYear()}-${String(date1.getMonth() + 1).padStart(2, '0')}-${String(date1.getDate()).padStart(2, '0')} ${'\u00A0'.repeat(25)} ${date2.getFullYear()}-${String(date2.getMonth() + 1).padStart(2, '0')}-${String(date2.getDate()).padStart(2, '0')}`}
        cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
        issues={dataTable?.issues ?? []}
      />
    </Box>
  );
};

export default CallCenterCustomerActivity;
