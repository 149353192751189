import { Autocomplete, CircularProgress, Grid, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { UserRoles } from '../../../../../utils/types/User';
import ModalWrapper from '../../PeerGroups/components/ModalWrapper';
import { type UserFiltered } from './UsersTableRow';

interface EditUserDetailsModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (user: UserFiltered) => Promise<void>;
  isLoading?: boolean;
  user: UserFiltered;
}

export const EditUserDetailsModal: React.FC<EditUserDetailsModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
  onSave,
  isLoading,
  user,
}) => {
  const handleSave = () => {
    void onSave(updatedUser);
  };

  const handleClose = () => {
    setUpdatedUser(user);
    onClose();
  };

  const buttons: Array<{
    label: string | JSX.Element;
    action: () => void;
    variant: 'filled' | 'outlined';
    disabled?: boolean;
  }> = [
    {
      label: 'Cancel',
      action: handleClose,
      variant: 'outlined',
      disabled: isLoading,
    },
    {
      label: isLoading === true ? <CircularProgress size={20} color="inherit" /> : 'Confirm',
      action: handleSave,
      variant: 'filled',
    },
  ];

  const { palette } = useTheme();
  const [updatedUser, setUpdatedUser] = useState<UserFiltered>({ ...user });

  useEffect(() => {
    setUpdatedUser({ ...user });
  }, [user, setUpdatedUser]);

  return (
    <ModalWrapper open={isOpen} handleClose={onClose} title={title} summary={text} buttons={buttons}>
      <Grid item xs={12} md={6}>
        <TextField
          label={
            <div style={{ backgroundColor: palette.green[100], color: palette.gray[500] }}>
              {"Enter User's First Name"}
            </div>
          }
          variant="outlined"
          fullWidth={true}
          value={updatedUser.firstName}
          onChange={(e) => {
            setUpdatedUser((prev) => ({ ...prev, firstName: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={
            <div style={{ backgroundColor: palette.green[100], color: palette.gray[500] }}>
              {"Enter User's Last Name"}
            </div>
          }
          variant="outlined"
          fullWidth={true}
          value={updatedUser.lastName}
          onChange={(e) => {
            setUpdatedUser((prev) => ({ ...prev, lastName: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={
            <div style={{ backgroundColor: palette.green[100], color: palette.gray[500] }}>Enter Email Address</div>
          }
          variant="outlined"
          fullWidth={true}
          value={updatedUser.email}
          onChange={(e) => {
            setUpdatedUser((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disablePortal
          value={updatedUser?.role}
          onChange={(e, selected) => {
            setUpdatedUser((prev) => ({ ...prev, role: selected ?? prev.role }));
          }}
          options={Object.values(UserRoles) as UserRoles[]}
          getOptionLabel={(option) => {
            switch (option) {
              case UserRoles.ADMIN:
                return 'Admin';
              case UserRoles.DATA_LEAD:
                return 'Data Lead';
              case UserRoles.DATA_COLLECTOR:
                return 'Data Collector';
              default:
                return '';
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <div style={{ backgroundColor: palette.green[100], color: palette.gray[500] }}>Select User Role</div>
              }
            />
          )}
        />
      </Grid>
    </ModalWrapper>
  );
};
