import { Box, Button, Modal, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { createContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import RedesignAppButton from '../../../../../components/materials/actions/RedesignAppButton';
import BackButton from '../../Workspaces/Workspace/components/BackButton';
import { Refresh } from '@mui/icons-material';
import mitt from 'mitt';

function a11yProps(index: number) {
  return {
    id: `outlier-analysis-tab-${index}`,
    'aria-controls': `outlier-analysis-tabpanel-${index}`,
  };
}

export const methodEmitter = mitt();
export const AnalysisContext = createContext<any>(null);

const OutlierAnalysis: React.FC = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { peerGroupId } = useParams();

  const legend = [
    { color: '#FFE1A8', label: 'Null Check Failed' },
    { color: '#A0D3EC', label: 'Zero Check Failed' },
    { color: '#FFA793', label: 'Range Check Failed' },
    { color: '#AD9FF3', label: 'Peer Count Check Failed' },
    { color: '#D682AC', label: 'Multiple Errors' },
    { color: '#FF4D6B', label: 'Negative Value Errors' },
    { color: '#93FFF1', label: 'Value v/s Median Check' },
  ];

  // tab control
  const [sections] = useState([
    { title: 'Revenues', route: 'revenues' },
    { title: 'Costs', route: 'costs' },
    { title: 'Employee (HR)', route: 'employee' },
    { title: 'Customers & Product', route: 'customers-product' },
    { title: 'Physical Channels', route: 'physical-channels' },
    { title: 'Digital Channels', route: 'digital-channels' },
    { title: 'Call Center Channels', route: 'call-center-channels' },
  ]);

  const [selectedTab, setSelectedTab] = useState<string>('revenues');
  const [tabToNavigate, setTabToNavigate] = useState<string>('revenues');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabToNavigate(newValue);
    if (isEditing) {
      setIsConfirmationModalOpen(true);
    } else {
      changeTab(newValue);
    }
  };

  const changeTab = function (newTab?: string) {
    setIsConfirmationModalOpen(false);
    setSelectedTab(newTab ?? tabToNavigate);
    navigate(`/app/peer-groups/${peerGroupId}/outlier-analysis/${newTab ?? tabToNavigate}`);
  };

  const location = useLocation();
  useEffect(() => {
    if (
      sections.find((section) => section.route === location.pathname.slice(location.pathname.lastIndexOf('/') + 1)) !==
      undefined
    ) {
      setSelectedTab(location.pathname.slice(location.pathname.lastIndexOf('/') + 1));
    }
  }, [location, sections]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <BackButton text="Back to Peer Groups" to="/app/peer-groups" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            backgroundColor: palette.gray.white,
            border: `1px solid ${palette.gray[300]}`,
            borderRadius: '8px',
            padding: '20px 32px',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="displaySmall">Outlier Analysis</Typography>
            <Box display="flex" gap={2}>
              {/* TO-DO
              <Button startIcon={<Tune />} style={{ textTransform: 'none', color: palette.green[500] }}>
                <Typography variant="labelSmall">Select Peers</Typography>
              </Button>   
              */}
              <Button
                startIcon={<Refresh />}
                style={{ textTransform: 'none', color: palette.green[500], padding: '0 12px' }}
                onClick={() => {
                  methodEmitter.emit('recalculateKPIs');
                }}
              >
                <Typography variant="buttonMedium">Recalculate Benchmark</Typography>
              </Button>
            </Box>
          </Box>

          <Box display="flex" gap={2}>
            {legend.map((item, idx) => (
              <Box display="flex" gap={1} key={idx}>
                <Box width="16px" height="16px" borderRadius="2px" sx={{ backgroundColor: item.color }}></Box>
                <Typography variant="bodySmall">{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          backgroundColor: palette.gray.white,
          border: `1px solid ${palette.gray[300]}`,
          borderRadius: '8px',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="standard"
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: palette.green[500] },
            '.MuiTabs-flexContainer': { justifyContent: 'space-between' },
          }}
        >
          {sections.map((section, idx) => (
            <Tab
              key={idx}
              value={section.route}
              label={
                <Typography variant="labelLarge" sx={{ textTransform: 'none' }}>
                  {section.title}
                </Typography>
              }
              disableRipple
              sx={{
                flexGrow: 1,
                textTransform: 'none',
                padding: '24px 32px',
                cursor: 'default',
                color: palette.gray[500],
                '&:first-of-type': { borderTopLeftRadius: '8px' },
                '&:last-of-type': { borderTopRightRadius: '8px' },
                '&:hover': {
                  backgroundColor: palette.green[100],
                },
                '&.Mui-selected': {
                  color: palette.green[500],
                  backgroundColor: palette.green[100],
                },
              }}
              {...a11yProps(1)}
            />
          ))}
        </Tabs>

        <AnalysisContext.Provider value={{ isEditing, setIsEditing }}>
          <Outlet />
        </AnalysisContext.Provider>
      </Box>

      <Modal
        open={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
      >
        <Box
          position="absolute"
          bgcolor="white"
          sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          p={2}
          display="flex"
          flexDirection="column"
          borderRadius={1}
          gap={3}
          width="580px"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="titleMedium">You have unsaved changes</Typography>
            <Typography variant="bodySmall">Do you wish to save your changes before leaving this page?</Typography>
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <RedesignAppButton
              appVariant="outlined"
              onClick={() => {
                changeTab();
              }}
            >
              Discard
            </RedesignAppButton>
            <RedesignAppButton
              appVariant="filled"
              onClick={() => {
                setIsConfirmationModalOpen(false);
              }}
            >
              Go Back
            </RedesignAppButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OutlierAnalysis;
