import React from 'react';
import {
  TextField,
  Autocomplete,
  type SxProps,
  type InputProps,
  type AutocompleteRenderGroupParams,
  useTheme,
} from '@mui/material';

interface AppAutoCompleteProps {
  options: any[];
  label?: string;
  placeholder?: string;
  sx?: SxProps;
  getOptionLabel?: (option: any) => string;
  groupBy?: (option: any) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  onChange?: (event: any, value: any) => void;
  InputProps?: InputProps & { inputProps?: React.InputHTMLAttributes<HTMLInputElement> };
}

const AppAutoComplete: React.FC<AppAutoCompleteProps> = ({
  options,
  label,
  placeholder,
  sx,
  getOptionLabel,
  groupBy,
  onChange,
  renderGroup,
  InputProps,
}) => {
  const { palette } = useTheme();
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel ?? ((option) => option)}
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{ ...params.InputProps, ...InputProps }}
          InputLabelProps={{
            style: { fontVariant: 'bodyLarge', color: palette.gray[500] },
          }}
          sx={{
            borderRadius: '8px',
            borderColor: palette.gray[450],
            '& .MuiAutocomplete-option': {
              fontVariant: 'bodyLarge',
              color: palette.gray[500],
            },
          }}
        />
      )}
      sx={sx}
      onChange={onChange}
    />
  );
};

export default AppAutoComplete;
