import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { PersonalBankingFTETables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { formatNumber } from '../../../../../../../../DataTables/utils/numberFormat';
import { sumValues } from '../../../../../../../../DataTables/utils/sumValues';
import { handleOpenComments } from '../../utils/handleOpenComments';

const BranchFTECompensation: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, PersonalBankingFTETables.BranchFTECompensation),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse, isLoading: isLoadingDataTable } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: PersonalBankingFTETables.BranchFTECompensation,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Branch manager', editable: true, indentLevel: 1 },
    { label: 'Specialized sales', editable: true, indentLevel: 1 },
    { label: 'Account manager', editable: true, indentLevel: 1 },
    { label: 'General sales & advice', editable: true, indentLevel: 1 },
    { label: 'Teller', editable: true, indentLevel: 1 },
    { label: 'Universal banker', editable: true, indentLevel: 1 },
    { label: 'Administration', editable: true, indentLevel: 1 },
    { label: 'Other', editable: true, indentLevel: 1 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Base pay ($)', editable: true },
    { key: 'B', label: 'Variable pay ($)', editable: true },
    { key: 'C', label: 'Benefits ($)', editable: true },
    { key: 'D', label: 'Total compensation ($)', editable: false },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: PersonalBankingFTETables.BranchFTECompensation,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B', 'C', 'D'],
          rowCount: 8,
          totalColumns: ['D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8'],
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const calculateTotals = (values: Record<string, number | null>) => {
    const d1 = formatNumber(sumValues(['A1', 'B1', 'C1'], values));
    const d2 = formatNumber(sumValues(['A2', 'B2', 'C2'], values));
    const d3 = formatNumber(sumValues(['A3', 'B3', 'C3'], values));
    const d4 = formatNumber(sumValues(['A4', 'B4', 'C4'], values));
    const d5 = formatNumber(sumValues(['A5', 'B5', 'C5'], values));
    const d6 = formatNumber(sumValues(['A6', 'B6', 'C6'], values));
    const d7 = formatNumber(sumValues(['A7', 'B7', 'C7'], values));
    const d8 = formatNumber(sumValues(['A8', 'B8', 'C8'], values));

    return {
      ...values,
      D1: d1,
      D2: d2,
      D3: d3,
      D4: d4,
      D5: d5,
      D6: d6,
      D7: d7,
      D8: d8,
    };
  };

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    const values = { ...dataTable?.values, [cellId]: newValue };
    const updatedValues = calculateTotals(values as Record<string, number | null>);
    dispatch(
      updateDataTable({
        dataTable: { tabKey, table: PersonalBankingFTETables.BranchFTECompensation, values: updatedValues },
        columns: ['A', 'B', 'C', 'D'],
        rowCount: 8,
      }),
    );
  };

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${workspace?.beginning_of_period} - ${workspace?.end_of_period}`}
          getColumnStyle={(column) => {
            if (column.label === 'Total compensation ($)') {
              return { backgroundColor: palette.blue[300] };
            }
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default BranchFTECompensation;
