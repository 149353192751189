/**
 * Formats an unformatted workspace name to be URL-safe.
 * @param workspaceName - The unformatted workspace name.
 * @returns A URL-safe formatted workspace name.
 */
export const formatNameForPath = (name: string): string => {
  const lowerCaseName = name.toLowerCase().trim();
  const formattedName = lowerCaseName
    .replace(/[\s&]+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/-+/g, '-');
  return formattedName;
};

/**
 * Converts a kebab-case string to a human-readable label.
 * For example, "my-kebab-case-string" becomes "My Kebab Case String".
 *
 * @param kebabCaseString - The kebab-case string to convert.
 * @returns A human-readable label.
 */
export const kebabCaseToLabel = (kebabCaseString: string, shouldCapitalize: boolean = true): string => {
  return kebabCaseString
    .split('_')
    .map((word) => (shouldCapitalize ? word.charAt(0).toUpperCase() + word.slice(1) : word))
    .join(' ');
};
