import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../../../utils/hooks/useAppSelector';
import {
  useGetDataTableQuery,
  useLazyGetAllCommentThreadsByDataTableQuery,
  useLazyGetIssuesQuery,
} from '../../../../../../../../../utils/redux/api';
import {
  addDataTable,
  selectDataTableByTabKeyAndTable,
  updateDataTable,
} from '../../../../../../../../../utils/redux/dataTablesSlice';
import { BranchAndATMCustomersTables } from '../../../../../../../../../utils/types/DataTablesEnum';
import TableComponent, {
  type Category,
  type Column,
} from '../../../../../../../../DataTables/TableComponent/TableComponent';
import { commentThreadsToCellSet } from '../../../../../../../../DataTables/utils/commentThreadsToCellSet';
import { getCommentThreads, getIssues } from '../../../../../../../../DataTables/utils/getCommentThreads';
import { handleOpenComments } from '../../utils/handleOpenComments';

const DigitalTransactions: React.FC = () => {
  const { palette } = useTheme();
  const tabKey = useAppSelector((state) => state.dataTables.activeTabKey);
  const { submoduleId: stringSubmoduleId } = useParams();
  const tableSubmoduleId = Number(stringSubmoduleId);
  const dispatch = useDispatch();
  const dataTable = useAppSelector((state) =>
    selectDataTableByTabKeyAndTable(state, tabKey, BranchAndATMCustomersTables.TransactionsInDigital),
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { activeWorkspace: workspace } = useWorkspaceDataContext();
  const { data: dataTableResponse, isLoading: isLoadingDataTable } = useGetDataTableQuery({
    submodule_id: tableSubmoduleId,
    table: BranchAndATMCustomersTables.TransactionsInDigital,
  });
  const [fetchDataTableCommentThreads] = useLazyGetAllCommentThreadsByDataTableQuery();
  const [fetchDataTableIssues] = useLazyGetIssuesQuery();

  const categories = useRef<Category[]>([
    { label: 'Number of customer initiated transactions', editable: true, indentLevel: 1 },
    { label: 'of which cash deposits', editable: true, indentLevel: 2 },
    { label: 'of which check deposits', editable: true, indentLevel: 2 },
    { label: 'of which cash withdrawals', editable: true, indentLevel: 2 },
    { label: 'of which transfers', editable: true, indentLevel: 2 },
    { label: 'of which P2P payments', editable: true, indentLevel: 2 },
    { label: 'of which wire or global transfers', editable: true, indentLevel: 2 },
    { label: 'of which bill payment', editable: true, indentLevel: 2 },
    { label: 'of which other', editable: true, indentLevel: 2 },
  ]);
  const columns = useRef<Column[]>([
    { key: 'A', label: 'Branch (#)', editable: true },
    { key: 'B', label: 'ATM (#)', editable: true },
  ]);

  useEffect(() => {
    if (!isLoadingDataTable) {
      dispatch(
        addDataTable({
          dataTable: {
            id: dataTableResponse?.id ?? 0,
            commentCount: dataTableResponse?.comments_amount ?? 0,
            submodule_id: tableSubmoduleId,
            tabKey,
            table: BranchAndATMCustomersTables.TransactionsInDigital,
            data: dataTableResponse?.data ?? [],
            columns: dataTableResponse?.columns ?? [],
            rows: dataTableResponse?.rows ?? [],
          },
          columns: ['A', 'B'],
          rowCount: 9,
        }),
      );
      dataTableResponse?.rows.forEach((rowName, index) => {
        categories.current[index].rowName = rowName;
      });

      dataTableResponse?.columns?.forEach((columnName, index) => {
        columns.current[index].columnName = columnName;
      });
    }
  }, [dispatch, dataTableResponse, isLoadingDataTable, tabKey, tableSubmoduleId]);

  useEffect(() => {
    if (dataTable === undefined) return;
    void getCommentThreads({
      dataTableId: dataTable.id,
      fetchDataTableCommentThreads,
      dispatch,
    });

    void getIssues({
      dataTableId: dataTable.id,
      submoduleId: tableSubmoduleId,
      dispatch,
      fetchDataTableIssues,
    });
  }, [dataTable, dispatch, fetchDataTableCommentThreads, fetchDataTableIssues, tableSubmoduleId]);

  const handleClick = (cellId: string, column: string, row: string) => {
    if (timer === null) {
      const newTimer = setTimeout(() => {
        setTimer(null);
      }, 250);
      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
      handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
    }
  };

  const handleValueChange = (cellId: string, newValue: number | null) => {
    dispatch(
      updateDataTable({
        dataTable: {
          tabKey,
          table: BranchAndATMCustomersTables.TransactionsInDigital,
          values: { ...dataTable?.values, [cellId]: newValue },
        },
        columns: ['A', 'B'],
        rowCount: 9,
      }),
    );
  };

  const date = new Date(workspace!.end_of_period);
  date.setDate(date.getDate() - 90);

  return (
    <Box>
      <Box>
        <TableComponent
          categories={categories.current}
          columns={columns.current}
          values={dataTable?.values ?? {}}
          onValueChange={handleValueChange}
          onClickCell={handleClick}
          onRightClickCell={(cellId, column, row) => {
            handleOpenComments(dispatch, dataTable?.id, { cellId, column, row });
          }}
          banner={true}
          bannerValue={`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`}
          getRowStyle={() => {
            return { backgroundColor: palette.green[200] };
          }}
          cellsWithCommentsSet={commentThreadsToCellSet(dataTable?.commentThreads)}
          issues={dataTable?.issues ?? []}
        />
      </Box>
    </Box>
  );
};

export default DigitalTransactions;
