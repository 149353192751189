import { Box, Divider, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { ToastContext } from '../../../../../../context/toastContext';
import { useAppSelector } from '../../../../../../utils/hooks/useAppSelector';
import { useGetUserSubmodulesQuery, useGetWorkspaceSubmodulesQuery } from '../../../../../../utils/redux/api';
import { selectUserRoles } from '../../../../../../utils/redux/authSlice';
import { formatNameForPath } from '../../../../../../utils/stringUtils';
import { type Submodule } from '../../../../../../utils/types/SubModule';
import SubmodulesTabs from './components/SubmodulesTabs';

export interface SubmodulePathItem extends Submodule {
  path: string;
}

const Data: React.FC = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const { handleOpenToast } = useContext(ToastContext);
  const userRoles = useAppSelector((state) => selectUserRoles(state));
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const {
    data: submodulesData,
    isFetching: fetchingSubmodules,
    refetch: refetchSubmodules,
  } = useGetWorkspaceSubmodulesQuery(Number(workspaceId));
  const { data: userSubmodules, isFetching: fetchingUserSubmodules } = useGetUserSubmodulesQuery(Number(workspaceId));

  useEffect(() => {
    if (fetchingSubmodules || fetchingUserSubmodules) return;
    if (firstLoad) {
      if (Array.isArray(submodulesData) && submodulesData.length > 0) {
        if (userRoles.includes('data_collector')) {
          // if data collector, direct to the first submodule assigned
          if (Array.isArray(userSubmodules) && userSubmodules.length > 0 && userRoles.includes('data_collector')) {
            navigate(`${formatNameForPath(userSubmodules[0].name as string)}/${userSubmodules[0].submodule_id}`);
          }
        } else {
          // if data lead or admin, direct to Revenues by default
          const defaultSubmodule =
            submodulesData.find((sub) => sub.name === 'Product and Segment') ?? submodulesData[0];
          navigate(`${formatNameForPath(defaultSubmodule.name as string)}/${defaultSubmodule.submodule_id}`);
        }
      } else {
        handleOpenToast({ severity: 'error', message: 'Error fetching submodules for the given workspace' });
      }
      setFirstLoad(false);
    }
  }, [
    firstLoad,
    fetchingSubmodules,
    submodulesData,
    fetchingUserSubmodules,
    userSubmodules,
    userRoles,
    navigate,
    handleOpenToast,
  ]);

  return (
    <Box bgcolor={palette.gray.white} borderRadius={2} border={`solid 1px ${palette.gray[300]}`} overflow="hidden">
      <SubmodulesTabs
        submodules={submodulesData ?? []}
        refetchSubmodules={async () => {
          await refetchSubmodules();
        }}
      />
      <Divider />

      <Outlet context={{ refetchSubmodules }} />
    </Box>
  );
};

export default Data;
