interface FAQSection {
  title: string;
  questions: Question[];
}

interface Question {
  number: number;
  prompt: string;
  answer: string;
}

export const faqSections: FAQSection[] = [
  {
    title: 'General Questions',
    questions: [
      {
        number: 1,
        prompt: 'What is the purpose of this benchmarking tool?',
        answer:
          'REBEX 2.0 is designed to help retail banks assess their performance relative to industry peers by analysing key metrics across various operational, financial, and customer experience areas. The tool allows participants to identify strengths, uncover areas for improvement, and make data-driven decisions to enhance their competitiveness. ',
      },
      {
        number: 2,
        prompt: 'Who can use this tool?',
        answer:
          'REBEX 2.0 is currently available to all retail banks who are interested in benchmarking their performance against industry standards. Access is granted based on partnership agreements, depending on the specific arrangements. ',
      },
      {
        number: 3,
        prompt: 'How frequently are the benchmarking results updated?',
        answer:
          'Benchmarking results are currently updated annually to reflect the most current data submitted by participants. This ensures that the benchmarking insights provided are timely and relevant for decision-making.',
      },
    ],
  },
  {
    title: 'Data Submission',
    questions: [
      {
        number: 4,
        prompt: 'How do I submit my data?',
        answer:
          'Data can be submitted through the REBEX 2.0 portal by uploading your files via a secure data submission form. Detailed instructions are provided within the portal, including a step-by-step guide on how to complete the submission process. Alternatively, you can also submit data',
      },
      {
        number: 5,
        prompt: 'What data formats are accepted for submission?',
        answer:
          'REBEX 2.0 accepts data in multiple formats, including Excel (.xlsx), CSV (.csv), and XML (.xml). It is recommended to use the provided template to ensure compatibility and ease of processing.',
      },
      {
        number: 6,
        prompt: 'What specific data is required for the benchmarking process?',
        answer:
          'The specific data required will vary depending on the focus of the benchmarking study. Typically, this includes financial data (e.g., revenue, expenses), operational metrics (e.g., transaction volumes, processing times), and customer experience indicators (e.g., customer satisfaction scores). A detailed list of required data fields is provided in the submission template.',
      },
      {
        number: 7,
        prompt: 'Is there a template available for data submission?',
        answer:
          'Yes, a standardized data submission template is available for download within the REBEX 2.0 portal. This template is designed to ensure that all required fields are completed accurately, facilitating a smooth submission process.',
      },
      {
        number: 8,
        prompt: 'How is my data protected?',
        answer:
          'Your data is protected using advanced encryption protocols both during transmission and while stored in our secure servers. Access to your data is restricted to authorized personnel only, ensuring that your information is handled with the highest level of confidentiality.',
      },
      {
        number: 9,
        prompt: 'Can I edit or update my submitted data?',
        answer:
          'Yes, you can edit or update your submitted data within a specified window before the quarterly benchmarking cycle is closed. After submission, you will receive a confirmation email with a link to make any necessary changes. If the submission window has closed, please contact the REBEX team at REBEX@bcg.com for assistance.',
      },
      {
        number: 10,
        prompt: 'What happens if I submit incomplete or incorrect data?',
        answer:
          'If incomplete or incorrect data is submitted, you will receive a notification detailing the issues found. You will have the opportunity to correct and resubmit the data before the deadline. If errors are not corrected, they may impact the accuracy of your benchmarking results.',
      },
      {
        number: 11,
        prompt: 'How can I track the status of my data submission?',
        answer:
          'You can track the status of your data submission through the REBEX 2.0 portal. Once logged in, you will find a dashboard displaying the progress of your submission, including any required actions and the final confirmation of acceptance.',
      },
    ],
  },
  {
    title: 'Data Quality and Security',
    questions: [
      {
        number: 12,
        prompt: 'How does the tool ensure the accuracy of the benchmarking data?',
        answer:
          'REBEX 2.0 employs a multi-step data validation process that includes automated checks for consistency, completeness, and accuracy. Any discrepancies or anomalies are flagged for review, ensuring that only high-quality data is used in the benchmarking process.',
      },
      {
        number: 13,
        prompt: 'What data validation checks are in place?',
        answer:
          'The tool runs several validation checks, including format consistency, range checks, and cross-field verification to ensure that the data submitted aligns with expected patterns and standards. Any data that does not pass these checks will be flagged for review and correction.',
      },
      {
        number: 14,
        prompt: 'How is the confidentiality of my data maintained?',
        answer:
          "Confidentiality is maintained through strict access controls, encryption, and anonymization of data in reports. Data is aggregated and anonymized before any benchmarking results are published, ensuring that no individual participant's data can be identified.",
      },
      {
        number: 15,
        prompt: 'Is my data shared with other participants?',
        answer:
          "No, your raw data is not shared with other participants. Only aggregated, anonymized results are shared in benchmarking reports, providing insights without compromising the confidentiality of any single institution's data.",
      },
      {
        number: 16,
        prompt: 'How is the data stored and protected?',
        answer:
          'Data is stored on secure, encrypted servers with access restricted to authorized personnel only. We employ industry-standard security practices, including regular security audits, to protect against unauthorized access or breaches.',
      },
      {
        number: 17,
        prompt: 'What happens to my data after the benchmarking process is complete?',
        answer:
          'After the benchmarking process is complete, your data is retained in our secure servers for a defined period, during which it is only used for generating historical comparisons and trends. After this period, data is either anonymized or securely deleted in accordance with data protection policies.',
      },
    ],
  },
  {
    title: 'Results and Reports',
    questions: [
      {
        number: 18,
        prompt: 'How can I access the benchmarking results?',
        answer:
          'Benchmarking results are accessible through the REBEX 2.0 portal. Once the results are published, you will be notified, and you can log in to view and download the reports.',
      },
      {
        number: 19,
        prompt: 'What types of reports are available?',
        answer:
          'REBEX 2.0 provides a variety of reports, including detailed performance dashboards, peer comparison reports, and trend analyses. Reports can be customized to focus on specific metrics or peer groups as needed.ß',
      },
      {
        number: 20,
        prompt: 'Can I download or export the benchmarking results?',
        answer:
          'Yes, you can download the benchmarking results in various formats, including PDF, Excel, and CSV, directly from the REBEX 2.0 portal. This allows you to further analyze or integrate the data with your internal systems.',
      },
      {
        number: 21,
        prompt: 'How can I interpret the benchmarking results?',
        answer:
          'Each report includes a detailed explanation of the metrics and how to interpret them. Additionally, you can access a guide within the portal that provides context on the benchmarking methodology and key indicators used in the analysis.',
      },
      {
        number: 22,
        prompt: 'Are there any resources available to help me understand the results?',
        answer:
          'Yes, REBEX 2.0 offers a variety of resources, including webinars, user guides, and interactive tutorials, to help you understand and make the most of your benchmarking results.',
      },
      {
        number: 23,
        prompt: 'Can I compare my performance against specific peers?',
        answer:
          'Yes, the tool allows you to select specific peers or peer groups for comparison. This feature enables you to benchmark your performance against competitors or similar-sized institutions.',
      },
      {
        number: 24,
        prompt: 'How is the performance benchmark calculated?',
        answer:
          'The performance benchmark is calculated using a weighted average of key metrics that reflect critical aspects of retail banking performance. These metrics are selected based on industry best practices and are periodically reviewed to ensure relevance.',
      },
      {
        number: 25,
        prompt: 'What metrics are used in the benchmarking process?',
        answer:
          'The benchmarking process includes a range of metrics across financial performance, operational efficiency, customer satisfaction, and risk management. A full list of metrics is available in the REBEX 2.0 portal under the "Metrics Overview" section.',
      },
    ],
  },
  {
    title: 'Support and Assistance',
    questions: [
      {
        number: 26,
        prompt: 'Who do I contact if I have issues with data submission or accessing results?',
        answer:
          'If you encounter any issues, please contact our support team via the REBEX 2.0 portal, email, or phone. Support details can be found in the "Help" section of the portal. Our team is available during business hours to assist with any questions or technical difficulties.',
      },
      {
        number: 27,
        prompt: 'Can I provide feedback on the tool?',
        answer:
          'Absolutely! We value your feedback and encourage you to share your thoughts on how we can improve REBEX 2.0. You can submit feedback directly through the portal or via email.',
      },
    ],
  },
  {
    title: 'Privacy and Compliance',
    questions: [
      {
        number: 28,
        prompt: 'How does the tool comply with data protection regulations?',
        answer:
          'REBEX 2.0 is fully compliant with all applicable data protection regulations, including GDPR and other regional standards. We have implemented strict data handling policies, encryption, and access controls to ensure that your data is managed in accordance with the highest privacy standards.',
      },
      {
        number: 29,
        prompt: 'Will my participation in the benchmarking be publicly disclosed?',
        answer:
          'No, your participation in the benchmarking process will not be publicly disclosed. All results are presented in an aggregated and anonymized form, ensuring that no individual institution is identifiable without explicit consent.',
      },
      {
        number: 30,
        prompt: 'What steps are taken to ensure compliance with industry standards?',
        answer:
          'We adhere to industry best practices for data security, privacy, and benchmarking methodologies. Regular audits, continuous monitoring, and updates to our processes ensure that REBEX 2.0 remains compliant with the latest standards and regulations.',
      },
    ],
  },
];
