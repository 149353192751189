interface GlossaryContent {
  title: string;
  tables: GlossaryTable[];
}

interface GlossaryTable {
  title: string;
  items: GlossaryItem[];
}

interface GlossaryItem {
  name: string;
  description?: string;
  subitem?: boolean;
}

export const glossaryContent: GlossaryContent[] = [
  {
    title: 'Revenues',
    tables: [
      {
        title: 'Products',
        items: [
          {
            name: 'Deposit products',
            description:
              'Total retail banking deposit business including current accounts, savings accounts, and term deposits',
          },
          {
            name: 'Current account',
            description:
              'A product that allows instant access to be available by withdrawals and deposits. Example: standard accounts, premium accounts, student accounts, youth accounts, cash accounts, and international accounts',
          },
          {
            name: 'Savings account',
            description:
              "A product that allows instant access to available funds and usually pays an interest rate per period. Examples: Call money, online savings accounts, children's savings account, saving books and cards, individual savings account, tax free savings account. Please exclude Term deposit accounts",
          },
          {
            name: 'Term deposit',
            description:
              'Savings deposit account with an interest rate where the customer cannot withdraw fund without penalty until the maturity term. This includes all term deposit accounts, regardless of term length (the term need not reach maturity during the study period)',
          },
          {
            name: 'Credit products',
            description: 'Total retail banking credit and loan business',
          },
          {
            name: 'Overdrafts',
            description:
              'An account that allows account holders to withdraw money even if their account balance falls below zero, granting them a line of credit. The bank typically charges interest and/or fees on the overdrawn amount until it is repaid.',
          },
          {
            name: 'Credit cards',
            description:
              'A credit product that allows customers to complete purchases with borrowed funds that must be paid back in complete, plus an interest amount, typically by using a plastic or online card. All payment forms (revolving and deferred debit) shall be included',
          },
          {
            name: 'Unsecured consumer loans',
            description:
              'Loans or credit lines without collateral guarantee of any form. Example: personal loan or line of credit',
          },
          {
            name: 'Secured consumer loans',
            description:
              'Loans or credit lines guaranteed by any type of collateral other than real estate. Example: auto loan or boat loan',
          },
          {
            name: 'Housing loans',
            description:
              'Loan or credit line where real estate (residential or commercial) is used as collateral. This includes both new mortgage drawdowns and mortgage refinances for new and existing customers',
          },
          {
            name: 'Mortgage',
            description:
              'Loan or credit line where real estate (residential or commercial) is used as collateral for housing purposes (purchase, build, renovate, etc.)',
          },
          {
            name: 'Home equity',
            description:
              'Loan or credit line without predefined purpose where real estate (residential or commercial) is used as collateral',
          },
          {
            name: 'Investment products',
            description:
              'Total retail bank investment products including bank securities accounts for debt and equity securities products (e.g. bonds and debentures, common stocks and derivative contracts) and any products with a securities component, e.g. mutual funds, retirement or pension plans. Also included are savings insurance products which are understood as an insurance policy where the primary customer is covered on savings/investments',
          },
          {
            name: 'Insurance products',
            description:
              "All insurance policies which are sold through the bank's sales channels and generate an income, either as a standalone product, or as linked / embedded to other products (PPI, unit-linked, etc.)",
          },
          {
            name: 'Other',
            description: 'All other products not previously stated',
          },
        ],
      },
      {
        title: 'Revenue by Product',
        items: [
          {
            name: 'Total revenue',
            description:
              'Total net reported revenues from operations including net interest, net fee and other income categories',
          },
          {
            name: 'Net interest income',
            description:
              'Net interest income realized on the lending and deposit business after taking into account the internal cost of fund',
          },
          {
            name: 'Net fee and other income',
            description:
              'Net fees collected related to the product during the study period (e.g., NSF, overdraft, maintenance, ATM, Debt card fees), and any other income (e.g. FX)',
          },
          {
            name: 'Gross interest income/expense',
            description:
              'Total interest paid to (for deposit accounts), or charged on (for loan products) clients, before accounting for the internal cost of funds',
          },
          {
            name: 'Average balance',
            description:
              'Balances in the respective product category calculated as the average of balances at the beginning and balances at the end of the data period',
          },
        ],
      },
      {
        title: 'Channels',
        items: [
          {
            name: 'Branch',
            description:
              'A physical channel for advisory and customer services located in a defined regional scope. Any retail outlet owned or rented by the bank and available for use by customers',
          },
          {
            name: 'ATM',
            description:
              'Cash machines located in public places that enable access to funds and other services. This number should include: in-branch and remote ATMs, IDMs that allow cash withdrawals and deposits and ATMs',
          },
          {
            name: 'Operating Expense',
            description:
              'Total direct and allocated cost for the personal banking (personnel, premises, IT, marketing, etc.)',
          },
          {
            name: 'Revenues',
            description:
              'Total net reported revenues from operations including net interest, net fee and other income categories for personal banking',
          },
          {
            name: 'Customer',
            description:
              'Total number of unique customers for the bank who hold at least one product with the bank for the data period',
          },
        ],
      },
    ],
  },
  {
    title: 'Costs',
    tables: [
      {
        title: 'Costs',
        items: [
          {
            name: 'Personnel costs',
            description:
              'Personnel costs include gross salaries, all bonuses, overtime pay and supplements, all (contractual and voluntary) special payments (vacation/holiday pay, year-end bonuses, profit sharing), anniversary awards, employer contributions to statutory social security, expenses for company pension plans, and severance payments',
          },
          {
            name: 'Premises costs',
            description:
              'Costs incurred from operating and maintaining buildings, facility management and utilities. Examples: rent, leasing, branch equipment (non-IT), and electricity. Please exclude amortization and depreciation',
          },
          {
            name: 'IT',
            description:
              'Costs incurred related to IT equipment, communication, software-applications. Examples: leasing, purchase costs, licensing costs, audio, etc. Please exclude amortization and depreciation',
          },
          {
            name: 'Marketing',
            description:
              'Costs pertaining to all marketing activities. Examples: television, online ads, newspaper, magazines, radio, direct and interactive marketing, social media marketing, PR events, and sponsorships',
          },
          {
            name: 'Digital marketing spend',
            description:
              'Marketing spend on online, tablet and smartphone channels excluding costs of creating adverts and agency fees',
          },
          {
            name: 'Other',
            subitem: true,
          },
          {
            name: 'Cash handling / vault services',
            description:
              'Costs related to cash transportation, processing and handling for all banking outlets (including branches and ATMs)',
          },
          {
            name: 'Outsourced staff (contractors)',
            description:
              'Costs of business processes (operations, excluding IT) outsourced to third party providers (payment processing, securities processing, and other processes)',
          },
          {
            name: 'Professional services (Consultancy, audit & legal)',
            description: 'Costs related to external advisory, audit and legal services',
          },
          {
            name: 'Postage',
            description: 'Costs related to the usage of postal services',
          },
          {
            name: 'Office equipment',
            description:
              'Costs related to office or workshop furniture and equipment, such as paper, desks, workbenches and tools',
          },
          {
            name: 'Regulatory fees',
            description:
              'Cost related to managing and meeting regulatory requirements, excluding IT cost for regulatory IT projects',
          },
          {
            name: 'Other expenses',
            description: 'Any other costs not listed separately (e.g. travel management, data services)',
          },
          {
            name: 'Operational losses',
            description:
              'Cost of losses resulting from inadequate or failed internal processes, people and systems or from external events',
          },
          {
            name: 'Amortization/Depreciation',
            description:
              'Accumulated portion of the recorded cost of a fixed asset that has been charged to expense through either depreciation or amortization',
          },
        ],
      },
      {
        title: 'Cost by Function',
        items: [
          { name: 'Front office', subitem: true },
          { name: 'Branches', description: 'Costs pertaining to the branch network' },
          { name: 'Online & mobile banking', description: 'Costs pertaining to online and mobile banking' },
          { name: 'Contact centers', description: 'Costs pertaining to the contact center' },
          {
            name: 'ATMs',
            description:
              'Costs pertaining to ATM-only locations, self-service outlets located in defined regional scope',
          },
          { name: 'Remote advisor', description: 'Costs pertaining to the remote advisory' },
          {
            name: 'Non-branch sales force',
            description: 'Costs pertaining to non-branch sales and sales performance measurement',
          },
          { name: 'Middle office', subitem: true },
          {
            name: 'Sales mgmt., Channel mgmt. & Product mgmt.',
            description:
              'Costs pertaining to channel and product management teams including product design, product marketing/campaign management, customer insights, analytics, overall strategy, customer analytics & CRM, customer experience and segment management, customer marketing strategy, etc.',
          },
          {
            name: 'Collections & recoveries',
            description:
              'Costs pertaining to teams working in teams dedicated to recovering past-due and delinquent amounts owned by customers.',
          },
          { name: 'Support function', subitem: true },
          {
            name: 'Operations',
            description:
              'Costs pertaining to operations (including credit decisioning) inclusive of all cost types defined above',
          },
          {
            name: 'Finance',
            description:
              'Costs pertaining to finance, accounting, and tax functions inclusive of all cost types defined above',
          },
          {
            name: 'Human resources',
            description: 'Costs pertaining to human resources functions inclusive of all cost types defined above',
          },
          {
            name: 'Risk management',
            description: 'Costs pertaining to risk functions inclusive of all cost types defined above',
          },
          {
            name: 'Compliance, internal audit & legal',
            description:
              'Costs pertaining to compliance, legal, and audit (control) functions inclusive of all cost types defined above',
          },
          {
            name: 'IT',
            description:
              'Costs pertaining to central IT functions inclusive of all cost types defined above. It excludes costs allocated to previously defined sales and service channels and other functions',
          },
          {
            name: 'Other',
            description: 'Any other costs within retail banking that were not included in the previous functions',
          },
        ],
      },
    ],
  },
  {
    title: 'Employee',
    tables: [
      {
        title: 'Employee',
        items: [
          { name: 'Front office', subitem: true },
          { name: 'Branches', description: 'All staff located in the branch network' },
          {
            name: 'Branch manager',
            description:
              'Branch managers are responsible for the operations and overall performance of a branch. A branch manager may also manage a portfolio of clients as a relationship banker or specialized sales force',
          },
          {
            name: 'Specialized sales',
            description:
              'Sales personnel with specialist product knowledge attending to customer requests for advisory and sales in specified product groups. Specialised sales FTEs typically have an assigned portfolio of clients in these product groups',
          },
          {
            name: 'Account manager',
            description:
              'Account managers serve an assigned client base with a focus on general relationship management. Customer requests concerning specialized products may be referred to a specialized sales representative or account manager',
          },
          {
            name: 'General sales and advice',
            description:
              'Sales personnel attending to customer requests for advisory and sales without an assigned portfolio of clients and with no product specific specialization',
          },
          {
            name: 'Teller',
            description:
              'Bank tellers perform account deposits and withdrawals, process manual customer transactions, and carry out non-specialised account management tasks for customers',
          },
          {
            name: 'Universal banker',
            description:
              'Universal bankers provide advisory services for clients in all standard deposit and credit products, and also perform teller duties (as defined above) as required. Universal bankers do not have a specific product specialisation',
          },
          {
            name: 'Administration',
            description:
              'Non-customer facing branch staff carrying out general administrative branch activities. Responsibilities include safe deposit boxes, cash management/balancing, compliance, loan file preparation, KYC requirements, and customer account processing',
          },
          {
            name: 'Other',
            description: 'Branch FTEs covering any activities not aligned with previously defined categories',
          },
          {
            name: 'Contact center',
            description:
              'FTEs in contact centers for both incoming and outgoing services (excluding remote advisory). Roles and activities in contact centers include management, generalist sales, specialist sales, and service',
          },
          {
            name: 'Remote advisor',
            description:
              'FTEs who offer financial advice, guidance, and services to clients through virtual platforms such as phone calls, video conferencing, or online messaging (excluding contact center).',
          },
          {
            name: 'Third party sales force',
            description:
              'FTEs from third parties selling bank products, including agents, hunters, and other third party sales personnel',
          },
          {
            name: 'Non branch sales',
            description:
              'Sales force FTEs not based in branches or in contact centers. Includes FTEs in mobile sales force directly employed by bank, typically located in centralized locations or with a defined regional remit, visiting customers directly or in a defined set of branches.',
          },
          { name: 'Middle office', subitem: true },
          {
            name: 'Sales mgmt., Channel mgmt. & Product mgmt.',
            description:
              'Middle office FTE working on:<br />- Customer-driven tasks: definitions of overall retail banking strategy, customer research and insight, customer experience and segment management, etc.<br />- Product-driven tasks: product development and updates, product lifecycle and performance management, etc.<br />- Channel-driven tasks: channel strategy, technical management of sales channels, definition and monitoring of sales channel goals, coordination of sales channels, etc.',
          },
          {
            name: 'Collections & recoveries',
            description:
              'FTEs dedicated to recovering past-due and delinquent amounts owned by customers. Does not include FTEs writing or managing collections/risk policy',
          },
          { name: 'Support function', subitem: true },
          {
            name: 'Operations',
            description:
              'Full time equivalents employed within that retail banking operation to support that retail banking operations and perform back office activities',
          },
          {
            name: 'Finance',
            description:
              'FTEs in business unit specific or central finance functions, including tax, accounting, controlling, reporting, working in or servicing the retail bank segment',
          },
          {
            name: 'Human resources',
            description:
              'FTEs in business unit specific or central human resources functions, including recruiting, talent and development, administration, etc. working in or servicing the retail bank segment',
          },
          {
            name: 'Risk management',
            description:
              'FTEs in business unit specific or central risk functions for all risk types (credit, market, liquidity, operational and others). Example: handling of policies, risk methodology, risk assessment, monitoring, recovery, etc',
          },
          {
            name: 'Compliance, internal audit & legal',
            description:
              'FTEs in compliance, legal, and control functions; includes those in AML and legal teams, internal audit/risk governance functions, those overseeing risk management compliance (second line of defence) and independent assurance (third line of defence)',
          },
          {
            name: 'IT',
            description:
              'FTEs in business unit specific or central technology functions working in or servicing the retail bank segment. Example: server maintenance, front-end development, IT support, etc',
          },
          {
            name: 'Other',
            description:
              'All other retail banking functions in the retail banking business unit or in central teams servicing retail banking not previously stated ',
          },
        ],
      },
    ],
  },
  {
    title: 'Customers & Product',
    tables: [
      {
        title: 'Customer segment',
        items: [
          {
            name: 'Total customers',
            description: 'Total number of customers for the bank who hold at least one product',
          },
          {
            name: 'Total active customers',
            description:
              'Active customers are defined as customers who have made at least one customer-initiated transaction in the last 6 months<br />For deposit customers, transactions include: deposits, withdrawals, payments and balance inquiries made in any channel. Customers with only recurring payments should be excluded.<br />For credit card only customers, transactions include: outgoing card payments or payments on their bill made in any channel.<br />For lending customers, transactions include: payments on their bill made in any channel',
          },
          {
            name: 'Mass market customers',
            description: 'Customers who are managed in the basic customer coverage model',
          },
          {
            name: 'Affluent customers',
            description:
              'Customers who are managed in a differentiated customer coverage model, which typically has certain an eligibility criteria',
          },
        ],
      },
      {
        title: 'New and lost customers',
        items: [
          {
            name: 'Total customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank',
          },
          {
            name: 'Total customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period',
          },
          {
            name: 'Mass market customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank and were managed in the mass market segment by the end of the study period',
          },
          {
            name: 'Mass market customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period and were managed in the mass market segment by the end of the study period',
          },
          {
            name: 'Affluent customers new-to-bank',
            description:
              'Number of customers who began their relationship with the bank (such as opening first account of any type) during the study period and who formerly had no relationship with the bank and were managed in the affluent segment by the end of the study period',
          },
          {
            name: 'Affluent customers lost from bank',
            description:
              'Number of customers who formally closed all activities with the bank or had all activities closed by the bank during the study period and were managed in the affluent segment by the end of the study period',
          },
        ],
      },
      {
        title: 'New product sales',
        items: [
          {
            name: 'Branch',
            description:
              'Number of products opened in branches. Example: opened by a branch manager, account manager or universal banker',
          },
          {
            name: 'Remote advisory',
            description:
              'Number of products opened via a remote advisor over virtual platforms such as phone calls, video conferencing, or online messaging',
          },
          {
            name: 'Non branch sales',
            description:
              'Number of products opened by non-branch sales force. Example: central sales, advisory center or non-branch based specialized advisors',
          },
          {
            name: 'Third party sales',
            description:
              'Number of products opened by a third party. Example: agencies/broker for mortgages or insurances, online platforms, comparison platforms or retailer',
          },
          {
            name: 'ATM',
            description:
              'Number of products opened via ATMs including any customer-facing device that is capable of handling cash. It also includes cash or cheque deposit machines (IDMs)',
          },
          {
            name: 'Contact center',
            description: 'Number of products opened by contact centers via telephone banking',
          },
          { name: 'Online', description: 'Number of products opened via secure site' },
          {
            name: 'Mobile',
            description:
              'Number of products opened via mobile banking app or mobile homepage version via smartphone or tablet',
          },
          {
            name: 'Total new products',
            description:
              'Number of products opened during the full period. Submit the information for total customers, accounts sold to new-to-bank customers, and sold to current account active customers. Criteria by product type:<br/>1) New deposit accounts and investment accounts should shall be counted regardless of any transactional activity within this period<br/>2) New mortgages, including instances where new account numbers are generated for extension, refinancing, and renewals<br/>3) New consumer loans (secured and unsecured), including renewals and extensions<br/>4) New credit cards opened and activated<br/>5) New investment accounts (including brokerage, mutual funds, Annuity, and IRA) opened<br/>6) New insurance sales',
          },
          {
            name: 'New products sold to new-to-bank customers',
            description:
              'Number of products opened during the full period to new-to-bank customers (New-to-bank customers are customers who began their relationship with the bank  during the study period and who formerly had no relationship with the bank)',
          },
        ],
      },
    ],
  },
  {
    title: 'Digital Channels',
    tables: [
      {
        title: 'Table Enrolment',
        items: [
          {
            name: 'Number of enrolled customers',
            description:
              "Number of total unique customers enrolled in the respective channels and are able to use the bank's services via these channels",
          },
          {
            name: 'Number of customers active in channel 90 day',
            description: 'Number of unique customers active in the respective channels in the last 90 days',
          },
          {
            name: 'Number of customers active in channel 30 day',
            description: 'Number of unique customers active in the respective channels in the last 30 days',
          },
        ],
      },
      {
        title: 'Interactions and transactions',
        items: [
          {
            name: 'Interactions',
            description:
              'Customer-initiated contact between customer and bank that may or may not result in a financial transaction (e.g. checking balance, account enquiry etc)., as defined below for each channel. Submit this information for full period of the study',
            subitem: true,
          },
          {
            name: 'Branch',
            description:
              'Number of customer branch visits. In case branch visits/interactions are not recorded please provide an estimate.',
          },
          {
            name: 'Remote advisor',
            description:
              'Number of interactions with an advisor through virtual platforms such as phone calls, video conferencing, or online messaging',
          },
          {
            name: 'ATM',
            description:
              'Number of unique authentications (customer initiated operations) including both operations not leading directly to changes in the account balances (such as including account statements, balance inquiry, last movements, PIN change, etc.)',
          },
          { name: 'Contact center', description: 'Number of total incoming calls handled by IVR and/or agents' },
          {
            name: 'Online',
            description:
              'Number of logins to online banking. Example: when a customer logs into online banking to check the account balance, it is counted as one interaction, but when customer proceeds to make a funds transfer online that is counted as a transaction',
          },
          { name: 'Mobile', description: 'Number of logins to mobile banking application' },
          { name: 'Other', description: 'Number of all other interactions not previously stated' },
          {
            name: 'Transactions',
            description:
              'Customer initiated actions or requests that lead to changes in account balances. Submit this information for full period of the study',
            subitem: true,
          },
          {
            name: 'Branch',
            description:
              'Number of transactions such as withdrawals, deposits, money transfer handled by branch FTE, exclude transactions at self-service terminals/ATM',
          },
          {
            name: 'Remote advisor',
            description:
              'Number of transactions done via with an advisor through virtual platforms such as phone calls, video conferencing, or online messaging',
          },
          {
            name: 'ATMs',
            description:
              'Number of withdrawals, deposits, and other transactions leading to changes in the account balance using an ATM or self-service terminal (exclude unsuccessful transactions)',
          },
          {
            name: 'Contact center',
            description:
              'Number of transactions leading to account balance changes initiated via contact center, either through IVR or contact center agent',
          },
          {
            name: 'Online',
            description:
              "Number of financial transactions initiated using online banking portal accessed through the bank's non-responsive secure site on a browser",
          },
          {
            name: 'Mobile',
            description:
              'Number of financial transactions initiated by mobile device using mobile-specific channels including banking through web browser on mobile device, mobile phone application, or other mobile device applications',
          },
          { name: 'Other', description: 'Number of all other transactions not previously stated' },
        ],
      },
    ],
  },
  {
    title: 'Physical Channels',
    tables: [
      {
        title: 'Physical Channels',
        items: [
          {
            name: 'Branch',
            description:
              'Number of transactions such as withdrawals, deposits, money transfer handled by branch FTE, exclude transactions at self-service terminals/ATM',
          },
          {
            name: 'ATMs',
            description:
              'Number of withdrawals, deposits, and other transactions leading to changes in the account balance using an ATM or self-service terminal (exclude unsuccessful transactions)',
          },
        ],
      },
    ],
  },
  {
    title: 'Virtual Channels',
    tables: [
      {
        title: 'Contact center metrics',
        items: [
          {
            name: 'Average phone waiting time',
            description:
              'Average waiting time until customers are served by contact center agents with no time spent in the interactive-voice-response (IVR) system, or time spent in the IVR system until customer is routed to a contact center agent. Both for sales & service calls',
          },
          {
            name: 'Average handling time per call - sales & advice',
            description:
              'Average handling time for servicing inbound customer calls (for both existing and new customers) answered by a contact center agent, when the content of the call is Sales and Advice. These calls might be handled by specialized or non-specialized staff',
          },
          {
            name: 'Average handling time per call - service',
            description:
              'Average handling time for servicing inbound customer calls (for both existing and new customers) answered by a contact center agent, when the content of the call is servicing existing accounts. Excludes calls handled by IVR only',
          },
          {
            name: 'Call abandonment rate',
            description:
              'Percentage of the incoming calls (Sales and Advice or Service) that the customer abandoned before the phone has been effectively answered',
          },
          {
            name: 'Share of incoming calls covered by IVR only',
            description:
              'Share of incoming calls handled only by IVR system with no contact center agent interaction. Both for sales & advice and service calls',
          },
          {
            name: 'First call resolution',
            description:
              'Share of customer calls resolved in one call as % of total service calls answered by a contact center agent (excluding calls handled by IVR only)',
          },
          {
            name: 'Total inbound calls (excluding IVR)',
            description:
              'All inbound calls are phone calls that are initiated by existing or potential bank customers. These calls are typically directed to an inbound contact centre, where they are answered and handled by agents',
          },
          {
            name: 'Total outbound calls',
            description:
              'All calls initiated by a contact center agent to a customer on behalf of the bank. Outbound calls are typically made to prospective customers and focus on sales and lead generation',
          },
        ],
      },
    ],
  },
];
