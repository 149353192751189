import { Box, Checkbox, checkboxClasses, FormControlLabel, Modal, Tooltip, Typography, useTheme } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';
import React, { useContext, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import RedesignAppButton from '../../../../../../../components/materials/actions/RedesignAppButton';
import AppCircularProgress from '../../../../../../../components/materials/loading/AppCircularProgress';
import { ToastContext } from '../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../context/WorkspaceDataContext';
import { useAppSelector } from '../../../../../../../utils/hooks/useAppSelector';
import { useAssignDataCollectorMutation } from '../../../../../../../utils/redux/api';
import { type SubmoduleAssign } from '../../../../../../../utils/types/SubmoduleAssign';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const emailRegex =
  /^(?:[a-zA-Z0-9_'^&+/=?`{|}~.-]+(?:\.[a-zA-Z0-9_'^&+/=?`{|}~.-]+)*)@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;

const DataCollectorModal: React.FC<Props> = ({ open, handleClose }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState<boolean>(false);
  const { submoduleId } = useParams();
  const { refetchSubmodule } = useWorkspaceDataContext();
  const userEmail = useAppSelector((state) => state.auth.userDetails.email);
  const [addMyselfCheckboxChecked, setAddMyselfCheckboxChecked] = useState(false);
  const { palette } = useTheme();
  const [assignDataCollectors, { isLoading }] = useAssignDataCollectorMutation();
  const { handleOpenToast } = useContext(ToastContext);
  const { refetchSubmodules } = useOutletContext<{ refetchSubmodules: () => Promise<void> }>();

  const handleSubmit = async () => {
    const assigments: SubmoduleAssign[] = emails.map((email) => ({
      user_email: email,
      submodule_id: [Number(submoduleId)],
    }));

    try {
      await assignDataCollectors(assigments).unwrap();

      handleOpenToast({ message: 'Data collectors saved sucessfully', severity: 'success' });
      handleClose();
      await refetchSubmodules();
      await refetchSubmodule();
    } catch {
      handleOpenToast({ message: 'Error saving data collectors', severity: 'error' });
    }
  };

  const handleDeleteChip = (chipValue: string) => {
    if (chipValue === userEmail) {
      setAddMyselfCheckboxChecked(false);
    }
  };

  const handleInputChange = (value: string) => {
    if (value.length > 0) {
      setUnconfirmedEmail(true);
    } else setUnconfirmedEmail(false);
  };

  const handleAddChip = () => {
    setUnconfirmedEmail(false);
  };

  const handleModalClose = () => {
    setEmails([]);
    setAddMyselfCheckboxChecked(false);
    setUnconfirmedEmail(false);
    handleClose();
  };

  const isButtonDisabled = isLoading || emails.length === 0 || unconfirmedEmail;

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        position="absolute"
        bgcolor="white"
        sx={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        p={2}
        display="flex"
        flexDirection="column"
        borderRadius={1}
        gap={3}
        width="580px"
      >
        <Typography variant="titleMedium">Assign Data Collector</Typography>
        <Typography variant="bodySmall">
          This action will assign someone to provide the bank&apos;s retail banking revenue for each product offered,
          broken down by net interest income and fee income.
        </Typography>
        <MuiChipsInput
          label={
            <div style={{ backgroundColor: palette.green[100], color: palette.gray[500] }}>
              Enter a valid e-mail address
            </div>
          }
          value={emails}
          onChange={(emails: string[]) => {
            setEmails(emails);
          }}
          onDeleteChip={handleDeleteChip}
          validate={(chipValue) => {
            return {
              isError: !emailRegex.test(chipValue),
              textError: 'Invalid email format',
            };
          }}
          onAddChip={handleAddChip}
          onInputChange={handleInputChange}
          hideClearAll
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={addMyselfCheckboxChecked}
              onChange={(e) => {
                setAddMyselfCheckboxChecked(e.target.checked);
                if (e.target.checked) {
                  setEmails((prev) => [...prev, userEmail ?? '']);
                } else {
                  setEmails((prev) => prev.filter((x) => x !== userEmail));
                }
              }}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: palette.green[500],
                },
              }}
            />
          }
          label="Add myself as Data Collector"
        />
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <RedesignAppButton appVariant={'outlined'} onClick={handleModalClose}>
            Discard
          </RedesignAppButton>
          <Tooltip
            title={isButtonDisabled ? 'Please make sure to confirm the email with Enter before submitting' : ''}
          >
            {/* span needed so the tooltip shows when button is disabled */}
            <span>
              <RedesignAppButton
                disabled={isButtonDisabled}
                onClick={handleSubmit}
                appVariant={'filled'}
                startIcon={isLoading ? <AppCircularProgress size={20} /> : null}
              >
                Complete
              </RedesignAppButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Modal>
  );
};

export default DataCollectorModal;
